import type { InputRef } from "antd";
import {
  Avatar,
  Divider,
  Space,
  Tabs,
  Tag,
  Typography,
  theme,
}from "antd";
import  {EditOutlined} from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { KpiPageHighlightGrid } from "components/Metrics";
import { useEffect, useRef, useState } from "react";
import { useOrganizationStore, useSearchStore, useSettingsStore, useUserInfoStore } from "store";
import { notification } from 'utility/notification';

import { SvgIcon } from "components/SvgIcon";
import PageWrapper from "../../components/PageWrapper";
import SaveSearchModal from "./SaveModal";
import SearchBox from "./searchBox";

import { commonIcons, searchIcons } from "assets/icons";
import { MetricLayoutIdentifier, Search, SearchMetaData, SearchTabType, dateTimeFormatOptions } from "types";
import ActivityOverview from "../../components/ActivityOverview";
import SearchTab from "./searchTab";

import { useMetricStore } from "store";
import { HLink } from "components/Link";
import { getTagColor } from "utility";

const { Title, Text, Link } = Typography;

export const SearchComponent = () => {
  const {
    token: { margin },
  } = theme.useToken();

  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const [loader, setLoader] = useState(false);
  const [editSearchId, setEditSearchId] = useState<string>("");
  const [editSearchModal, setEditSearchModal] = useState(false);
  const searchInputRef = useRef<InputRef>(null);
  
  const {
    mspEnabled,
    context,
    tags,
  } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    context: state.context,
    tags: state.tags
  }));
  const userInfos = useUserInfoStore((state) => state.userInfos);

  const { 
    activeTab, 
    savedSearchPage , 
    recentSearchPage, 
    popularSearchPage, 
    getSearchItems, 
    setSearchQuery, 
    updateSearch, 
    deleteSearch, 
    setActiveTab,
    searchFilters
  } =
    useSearchStore((state) => ({
      activeTab: state.activeTab,
      savedSearchPage: state.searchPage[SearchTabType.SavedSearches],
      recentSearchPage: state.searchPage[SearchTabType.RecentSearches],
      popularSearchPage: state.searchPage[SearchTabType.PopularSearches],
      getSearchItems: state.getSearchItems,
      setSearchQuery: state.setSearchQuery,
      updateSearch: state.updateSearch,
      deleteSearch: state.deleteSearch,
      setActiveTab: state.setActiveTab,
      searchFilters: state.searchFilters,
    }));

  const savedSearchColumns: ColumnsType<Search> = [
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (_, search) => (
        <HLink onClick={() => {
          setSearchQuery({id: search.id, query: search.query, nativeQuery: search.nativeQuery, name: search.name, description: search.description, isAiBasedQuery: search.isAiBasedQuery} as SearchMetaData);
          searchInputRef?.current?.focus();
          }} text={search.query} 
        />
        
      ),
    },
   
    {
      title: "Owner",
      dataIndex: "userID",
      key: "userID",
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Tag",
      dataIndex: "tenantID",
      key: "tenantID",
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: searchFilters[SearchTabType.SavedSearches]?.["tenantID"],
      render: (tenantID: string) => {
        const tag = tags?.find((tag) => (tag.id == `${tenantID}_tag`));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (_, search) => (
        <Space>
          {`${search.tenantID}_tag` == context
            ?
            <HLink
              onClick={() => {
                setEditSearchId(search?.id)
              }}
              text={search.name}
            />
            :
            <Text>{search.name}</Text>
          }
        </Space>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (_, search) => (
        <Space>
          {`${search.tenantID}_tag` == context
            ?
            <HLink
              onClick={() => {
                setEditSearchId(search?.id)
              }}
              text={search.description}
            />
            :
            <Text>{search.description}</Text>
          }
        </Space>
      ),
    },
    {
      title: "Last Update On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (updatedAt: string) =>
        new Date(updatedAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        ),
    },
  ];

  const recentPopularSearchColumns: ColumnsType<Search> = [
    {
      title: "Query",
      dataIndex: "query",
      key: "query",
      render: (_, search) => (
        <HLink onClick={() => {
          setSearchQuery({query: search.query, nativeQuery: search.nativeQuery, name: search.name, description: search.description, isAiBasedQuery: search.isAiBasedQuery} as SearchMetaData);
          searchInputRef?.current?.focus();
          }} text={search.query} 
        />
      ),
    },
    {
      title: "Hits",
      dataIndex: "hits",
      key: "hits",
    },
  ];

  const updateEditedSearch = async (name: string, description: string) => {
    try {
      setLoader(true);
      await updateSearch(editSearchId, name, description);
      setEditSearchModal(false);
      notification.success({
        message: "Search updated successfully",
        duration: 6,
      });
      await getSearchItems(SearchTabType.SavedSearches, savedSearchPage.number, savedSearchPage.size);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while updating your search...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnDeleteSavedSearches = async (itemIds: string[]) => {
    try {
      setLoader(true);
      await Promise.all(
        itemIds.map(async (searchId) => await deleteSearch(searchId))
      );
      notification.success({
        message: "Search deleted successfully",
        duration: 6,
      });
      
      await getSearchItems(SearchTabType.SavedSearches, savedSearchPage.number, savedSearchPage.size);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting your search...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  // for edit
  useEffect(() => {
    if (editSearchId) {
      setEditSearchModal(true);
    }
  }, [editSearchId]);

  const { token } = theme.useToken();
  const metrics = useMetricStore((state) => state.metrics);
  const fetchMetricsForLayout = useMetricStore((state) => state.fetchMetricsForLayout);

  useEffect(() => {
    const asyncUseEffect = async () => {
      fetchMetricsForLayout(MetricLayoutIdentifier.MetricLayoutSearch);
    };
    asyncUseEffect();
  }, []);

  return (
    <PageWrapper
      // feedback={<ActivityOverview pageSize={9} resizable={true} />}
      highlights={
        <KpiPageHighlightGrid key="workflows" metrics={metrics && metrics.get(MetricLayoutIdentifier.MetricLayoutSearch)} />
      }
      showSearch={false}
      content={
        <div style={{ margin, width: "100%" }}>
          <SearchBox />
          <Title level={3}>Search</Title>
          {useDivider && <Divider />}
          <Tabs
            defaultActiveKey={activeTab}
            onChange={(key) => setActiveTab(key as SearchTabType)}
            items={[
              {
                label: (
                  <Space size={token.marginXXS}>
                    <SvgIcon component={commonIcons.savedIcon} />
                    <Text>Saved</Text>
                  </Space>
                ),
                key: SearchTabType.SavedSearches,
                children: (
                  <SearchTab
                    searchTabType={SearchTabType.SavedSearches}
                    searchPageInfo={savedSearchPage}
                    headerPrefix="Saved Searches"
                    searchItemColumns={savedSearchColumns}
                    searchItemsDelete={OnDeleteSavedSearches}
                  />
                ),
              },
              {
                label: (
                  <Space size={token.marginXXS}>
                    <SvgIcon component={commonIcons.recentIcon} />
                    <Text>Recent</Text>
                  </Space>
                ),
                key: SearchTabType.RecentSearches,
                children: (
                  <SearchTab
                    searchTabType={SearchTabType.RecentSearches}
                    searchPageInfo={recentSearchPage}
                    headerPrefix="Recent Searches"
                    searchItemColumns={recentPopularSearchColumns}
                  />
                ),
              },
              {
                label: (
                  <Space size={token.marginXXS}>
                    <SvgIcon component={commonIcons.popularIcon} />
                    <Text>Popular</Text>
                  </Space>
                ),
                key: SearchTabType.PopularSearches,
                children: (
                  <SearchTab
                    searchTabType={SearchTabType.PopularSearches}
                    searchPageInfo={popularSearchPage}
                    headerPrefix="Popular Searches"
                    searchItemColumns={recentPopularSearchColumns}
                  />
                ),
              },
            ]}
          />
          {editSearchModal && (
            <SaveSearchModal
              onClose={() => {
                setEditSearchModal(false);
                setEditSearchId("");
              }}
              loader={loader}
              open={editSearchModal}
              onSubmit={(name: string, description: string) => 
                updateEditedSearch(name, description)
              }
              isEditId={editSearchId}
            />
          )}
        </div>
      }
    ></PageWrapper>
  );
};
