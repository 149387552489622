import { FC, useEffect, useState } from "react";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";

export interface RichEditorProps {
  height?: string;
  maxChars?: number;
  editMode?: boolean;
  content: string;
  onChange?: (content: string) => void;
}

const RichEditor: FC<RichEditorProps> = ({ 
  height,
  maxChars,
  editMode,
  content,
  onChange
}) => {
  const [textLength, setTextLength] = useState(0);


  useEffect(() => {
    if (maxChars) {
      const text = content.replace(/<\/?[^>]+(>|$)/g, "");
      if (text.length <= maxChars) {
        setTextLength(text.length);
      } else {
        setTextLength(maxChars);
      }
    }
  }, [content]);

  const toolbar = [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline'],
    [{'color': [] }],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image'],
  ];

  const formats = [
    'header',
    'bold', 'italic', 'underline',
    'color',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ height: height }}> 
        <ReactQuill
          theme={editMode ? "snow" : "bubble"}
          style={{ height: '100%' }}
          modules={{
            toolbar: editMode ? toolbar : false,
            clipboard: {matchVisual: false}
          }}
          formats={formats}
          readOnly={!editMode}
          value={content} 
          onChange={(value) => {
            if (maxChars) {
              const text = value.replace(/<\/?[^>]+(>|$)/g, "");
              if (text.length <= maxChars) {
                setTextLength(text.length);
                onChange?.(value);
              } else {
                setTextLength(maxChars);
                onChange?.(text.slice(0, maxChars));
              }
            } else {
              onChange?.(value);
            }
          }}
        />
      </div>
      {maxChars && 
        <div style={{
          marginTop: '50px',
          fontSize: '12px',
          color: '#555',
          textAlign: 'right'
        }}>
          {textLength}/{maxChars}
        </div>
      }
    </div>
  )
};

export default RichEditor;
