import { CaseActiviy, CaseAttachment, CaseNote } from "types";


export enum CaseTabType {
    Info = "info",
    Notes = "notes",
    Attachements = "attachements",
    Links = "links",
    Tasks = "tasks"
}

export enum CaseStatus {
    New = "new",
    InProgress = "in_progress",
    OnHold = "on_hold",
    Resolved = "resolved",
    Closed = "closed"
}

export const CaseStatusMap: Map<CaseStatus, string> = new Map<CaseStatus, string>(
    [
        [CaseStatus.New, "New"],
        [CaseStatus.InProgress, "In Progress"],
        [CaseStatus.OnHold, "On Hold"],
        [CaseStatus.Resolved, "Resolved"],
        [CaseStatus.Closed, "Closed"]
    ]
)

export enum CaseSeverity {
    Critical = "critical",
    High = "high",
    Medium = "medium",
    Low = "low",
    Informational = "informational", 
}

export const CaseSeverityMap: Map<CaseSeverity, string> = new Map<CaseSeverity, string>(
    [
        [CaseSeverity.Critical, "Critical"],
        [CaseSeverity.High, "High"],
        [CaseSeverity.Medium, "Medium"],
        [CaseSeverity.Low, "Low"],
        [CaseSeverity.Informational, "Informational"]
    ]
)

export enum CasePriority {
    P1 = "P1",
    P2 = "P2",
    P3 = "P3",
    P4 = "P4",
    P5 = "P5", 
}

export const CasePriorityMap: Map<CasePriority, string> = new Map<CasePriority, string>(
    [
        [CasePriority.P1, "P1"],
        [CasePriority.P2, "P2"],
        [CasePriority.P3, "P3"],
        [CasePriority.P4, "P4"],
        [CasePriority.P5, "P5"]
    ]
)

export enum CaseSource {
    User = "user",
    System = "system"
}

export const CaseSourceMap: Map<CaseSource, string> = new Map<CaseSource, string>(
    [
        [CaseSource.User, "User"],
        [CaseSource.System, "System"]
    ]
)

export interface Case {
    id: string;
    number: string;
    name: string;
    description: string;
    status: CaseStatus;
    category: string;
    severity: CaseSeverity;
    priority: CasePriority;
    source: CaseSource;
    sla: number;
    assigneeID: string;
    closedAt: string;
    tags: string[];
    tagID: string;
    userID: string;
    tenantID: string;
    caseNote: CaseNote;
    createdAt: string;
    updatedAt: string;
    caseNotes: CaseNote[];
    caseActivities: CaseActiviy[];
    caseAttachments: CaseAttachment[];
}

export type CaseUploadObjectType = Omit<Case, 'number' | 'userID' | 'source' | 'tagID' | 'tenantID' | 'caseNotes' | 'caseActivities' | 'caseAttachments' | 'updatedAt' | 'createdAt'>;
