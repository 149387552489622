import {
  Action, Trigger, ActionUploadObjectType, TriggerUploadObjectType, 
  ActionProvider, ActionProviderUploadObjectType, TriggerProvider, TriggerProviderUploadObjectType, ArtifactProviderUploadObjectType, Artifact, ArtifactProvider, App, AppUploadObjectType, AppSubscription, AppSubscriptionUploadObjectType,
  Workflow, WorkflowUploadObjectType, ArtifactUploadObjectType,WorkflowNodeType, 
  Edge, EdgeUploadObjectType
} from 'types';
import { Case, CaseNote, CaseNoteUploadObjectType, CaseUploadObjectType } from 'types/casemgmt';

class DTOFactory {
  convertActionToUploadObject = (action: Action) : ActionUploadObjectType=> {
    return {
      id: action.id,
      name: action.name,
      displayName:  action.displayName,
      description: action.description,
      comments: action.comments,
      type: action.type,
      accessType: action.accessType,
      parameters: action.parameters,
      outputs: action.outputs,
    } as ActionUploadObjectType
  };

  convertTriggerToUploadObject = (trigger: Trigger) : TriggerUploadObjectType=> {
    return {
      id: trigger.id,
      name: trigger.name,
      displayName:  trigger.displayName,
      description: trigger.description,
      comments: trigger.comments,
      type: trigger.type,
      parameters: trigger.parameters,
      outputs: trigger.outputs,
    } as TriggerUploadObjectType
  };
  convertArtifactToUploadObject = (artifact: Artifact): ArtifactUploadObjectType => {
    return {
      id : artifact.id,
      name: artifact.name,
      displayName:  artifact.displayName,
      description: artifact.description,
      comments: artifact.comments,
      fields: artifact.fields,
    } as ArtifactUploadObjectType
  }

  convertActionProviderToUploadObject = (actionProvider: ActionProvider) : ActionProviderUploadObjectType=> {
    return {
      id: actionProvider.id,
      name: actionProvider.name,
      displayName:  actionProvider.displayName,
      description: actionProvider.description,
      comments: actionProvider.comments,
      actionID: actionProvider.actionID,
      appID: actionProvider.appID,
      parameters: actionProvider.parameters,
      outputs: actionProvider.outputs,
      adapterType: actionProvider.adapterType,
      http: actionProvider.http,
    } as ActionProviderUploadObjectType
  }

  convertTriggerProviderToUploadObject = (triggerProvider: TriggerProvider) : TriggerProviderUploadObjectType=> {
    return {
      id: triggerProvider.id,
      name: triggerProvider.name,
      displayName:  triggerProvider.displayName,
      description: triggerProvider.description,
      comments: triggerProvider.comments,
      triggerID: triggerProvider.triggerID,
      appID: triggerProvider.appID,
      parameters: triggerProvider.parameters,
      outputs: triggerProvider.outputs,
      adapterType: triggerProvider.adapterType,
      http: triggerProvider.http,
    } as TriggerProviderUploadObjectType
  }

  convertArtifactProviderToUploadObject = (artifactProvider: ArtifactProvider) : ArtifactProviderUploadObjectType=> {
    return {
      id: artifactProvider.id,
      name: artifactProvider.name,
      displayName:  artifactProvider.displayName,
      description: artifactProvider.description,
      comments: artifactProvider.comments,
      artifactID: artifactProvider.artifactID,
      appID: artifactProvider.appID,
      adapterType: artifactProvider.adapterType,
      http: artifactProvider.http,
    } as ArtifactProviderUploadObjectType
  }


  convertAppToUploadObject = (app: App) : AppUploadObjectType=> {
    return {
      id: app.id,
      comments: app.comments,
      name: app.name,
      displayName: app.displayName,
      description: app.description,
      logoUrl: app.logoUrl,
      darkLogoUrl: app.darkLogoUrl,
      logoBuffer: app.logoBuffer,
      darkLogoBuffer: app.darkLogoBuffer,
      edgeDetector: app.edgeDetector,
      tags: app.tags,
    } as AppUploadObjectType
  }

  convertAppSubscriptionToUploadObject = (appSubscription: AppSubscription) => {
    return {
      id: appSubscription.id,
      name: appSubscription.name,
      appID: appSubscription.appID,
      edgeID: appSubscription.edgeID,
      edgeSubscriptionID: appSubscription.edgeSubscriptionID,
      parameters: appSubscription.parameters,
      httpConfiguration: appSubscription.httpConfiguration,
      sqlDBConfiguration: appSubscription.sqlDBConfiguration,
      webhookConfiguration: appSubscription.webhookConfiguration,
      tenantID: appSubscription.tenantID
    } as AppSubscriptionUploadObjectType
  }

  convertWorkflowToUploadObject = (workflow: Workflow) : WorkflowUploadObjectType=> {
    const obj = {
      id: workflow.id,
      name: workflow.name,
      description: workflow.description,
      isConfigured: workflow.isConfigured,
      isSubworkflow: workflow.isSubworkflow,
      enabled: workflow.enabled,
      parameters: workflow.parameters,
      triggerRef: workflow.triggerRef,
      steps: workflow.steps,
      outputs: workflow.outputs,
      timeoutPolicy: workflow.timeoutPolicy,
      timeoutSeconds: workflow.timeoutSeconds,
      importTags: workflow.importTags,
    } as WorkflowUploadObjectType;
    Object.values(obj?.steps).filter((workflowStep) => workflowStep.type == WorkflowNodeType.Action).forEach(actionStep => {
      const step = actionStep as any;
      step.deps = {};
    });
    return obj;
  }

  convertEdgeToUploadObject = (edge: Edge) : EdgeUploadObjectType=> {
    const obj = {
      id: edge.id,
      name: edge.name,
      displayName: edge.displayName,
      description: edge.description,
      manageType: edge.manageType,
      infraType: edge.infraType,
      configuration: edge.configuration,
      reachability: edge.reachability,
      deployMode: edge.deployMode,
      region: edge.region,
      tags: edge.tags
    } as EdgeUploadObjectType;
    return obj;
  }

  convertCaseToUploadObject = (c: Case) : CaseUploadObjectType=> {
    const obj = {
      id: c.id,
      name: c.name,
      description: c.description,
      status: c.status,
      category: c.category,
      severity: c.severity,
      priority: c.priority,
      sla: c.sla,
      assigneeID: c.assigneeID,
      closedAt: c.closedAt,
      tags: c.tags,
      caseNote: c.caseNote
    } as CaseUploadObjectType;
    return obj;
  }

  convertCaseNoteToUploadObject = (c: CaseNote) : CaseNoteUploadObjectType=> {
    const obj = {
      id: c.id,
      caseID: c.caseID,
      title: c.title,
      content: c.content
    } as CaseNoteUploadObjectType;
    return obj;
  }
}

export const dtoFactory = new DTOFactory();