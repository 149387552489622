import { Form, Input, Modal, Select } from "antd";
import { notification } from "utility/notification";

import { useEffect, useState } from "react";
import { Widget, WidgetType } from "types";
import { SearchFormInput } from "../../pages/Home/SearchFormInput";

export interface WidgetEditProps {
  dashboardId: string;
  widget?: Widget;
  isAdd: boolean;
  onSave: (widget: Widget) => Promise<void>;
  onClose: () => Promise<void>;
}

export const WidgetEdit = (props: WidgetEditProps) => {
  const [, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [modalState, setModalState] = useState(false);

  const handleSubmit = async () => {
    let values: any;
    let hasValidationError = false;

    try {
      setLoading(true);
      try {
        values = await form.validateFields();
      } catch (error) {
        console.log("Form has validation error", form.getFieldsValue());
        hasValidationError = true;
        throw error;
      }

      const widgetToSave = props.widget
        ? {
            ...props.widget,
            ...values,
            description: values.title,
          }
        : { ...values, description: values.title };
      props.onSave(widgetToSave);
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to ${
            props.isAdd ? "create" : "update"
          } widget, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoading(false);
      if (!hasValidationError) {
        onClose();
      }
    }
  };

  useEffect(() => {
    const asyncUseEffect = async () => {
      /** If this is a new user then the userRoles state should be reset */
      setModalState(true);
      try {
        setLoading(true);
        if (props.widget) {
          const fields = {
            type: props.widget.type,
            unit: props.widget.unit,
            title: props.widget.title,
            description: props.widget.description,
            search: props.widget.search,
          };
          form.setFieldsValue(fields);
          /** All assignable roles, to be passed to the Assignment component */
        }
      } catch (error) {
        notification.error({
          message: `Failed to fetch widgets, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    };
    asyncUseEffect();
  }, []);

  const onClose = async () => {
    setModalState(false);
    await props.onClose();
  };

  return (
    <Modal
      onCancel={onClose}
      onOk={handleSubmit}
      title={"Widgets"}
      open={modalState}
      width={1024}
    >
      <div>
        <Form
          form={form}
          labelCol={{ span: 8, offset: 0 }}
          wrapperCol={{ span: 16, offset: 0 }}
        >
          <div id={"title"}>
            <Form.Item
              id={"title"}
              label={"Title"}
              name={"title"}
              rules={[
                { required: true, message: "Please input metric title!" },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div>
            <SearchFormInput
              form={form}
              label="Data Source"
              name="search"
              search={props.widget?.search}
              validation={[
                { required: true, message: "Please input data source!" },
              ]}
            />
          </div>
          <div id={"type"}>
            <Form.Item
              id={"type"}
              label={"Widget Type"}
              name={"type"}
              rules={[{ required: true, message: "Please input widget type!" }]}
            >
              <Select
                onSelect={(value) => form.setFieldsValue({ type: value })}
                defaultValue={[props.widget?.type]}
              >
                <Select.Option
                  key={WidgetType.MiniTile}
                  value={WidgetType.MiniTile}
                >
                  {"Tile"}
                </Select.Option>
                <Select.Option key={WidgetType.Trend} value={WidgetType.Trend}>
                  {"Trend"}
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};
