import { Empty, theme } from "antd";
import { useEffect, useMemo } from "react";
import { Layouts } from "react-grid-layout";
import { createUseStyles } from "react-jss";

import { Layout, Responsive, WidthProvider } from "react-grid-layout";
import { Dashboard, Metric, Widget, breakpoints, cols } from "types";
import { MetricDisplay } from "./MetricDisplay";

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface KpiGridProps {
  dashboard: Dashboard;
  stretchMiniTiles?: boolean;
  metrics: { [k: string]: Metric };
  onWidgetColorChange?: (id: string, color: string) => Promise<void>;
  onLayoutChange?: (layout: Layout[], layouts: Layouts) => Promise<void>;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  showWidgetOperations?: boolean;
  isDraggable?: boolean;
  isDroppable?: boolean;
}

export const KpiGrid = (props: KpiGridProps) => {
  const { token } = theme.useToken();
  const getChildren = (widgets: Widget[], metrics: { [k: string]: Metric }) => {
    const displayMetrics = widgets.map((widget) => widget.id);
    return displayMetrics.map((metric) => {
      const metricData = metrics[metric];
      if (!metricData) return <Empty key={metric}></Empty>;
      return (
        <div key={metric}>
          <MetricDisplay
            key={metric}
            metric={props.metrics[metric]}
            color={props.dashboard.widgets.find((w) => w.id === metric)?.color}
            stretchMiniTiles={props.stretchMiniTiles}
            onDelete={props.onDelete}
            onEdit={props.onEdit}
            onRefresh={props.onRefresh}
            onChangeColor={props.onWidgetColorChange?.bind(null, metric)}
            showOperations={props.showWidgetOperations}
          />
        </div>
      );
    });
  };

  const children = useMemo(() => {
    return getChildren(props.dashboard?.widgets, props.metrics);
  }, [props.dashboard, props.metrics, props.isDraggable, props.isDroppable]);

  const useStyles = createUseStyles({
    container: {
      width: "100%",
      position: "relative",
    },
  });

  const classes = useStyles();

  return (
    <div id={props.dashboard.id} className={classes.container}>
      <ResponsiveGridLayout
        rowHeight={100}
        layouts={props.dashboard.layouts}
        breakpoints={breakpoints}
        cols={cols}
        verticalCompact={true}
        onLayoutChange={props.onLayoutChange}
        resizeHandles={[]}
        containerPadding={[token.padding, token.padding]}
        margin={[token.marginXXS, token.marginXXS]}
        isDraggable={props.isDraggable}
        isDroppable={props.isDroppable}
      >
        {children}
      </ResponsiveGridLayout>
    </div>
  );
};
