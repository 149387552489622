import { AxiosConfig, axiosInterceptor } from "./axios";
import { AxiosHeaders, AxiosResponse } from "axios";
import { casesApiPath, caseActivitiesApiPath, caseNotesApiPath, jsonApiDeserializer, caseAttachmentsApiPath } from "./constant"
import { dtoFactory } from "utility/resource";
import { Case, CaseAttachment, CaseNote } from "types";
import { CaseActiviy } from "types/casemgmt/activity";

export const getCasesApi = async (
  pageNumber?: number,
  pageSize?: number,
  filters?: string[],
  sorter?: string[],
  ) : Promise<[Case[], number]>=> {
  
  let url = `${casesApiPath}?`

  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get", url);
  return [jsonApiDeserializer.deserialize(response.data) as Case[], response?.data?.meta?.totalCount];
};

export const getCaseApi = async (caseId: string) : Promise<Case>=> {
  const response = await axiosInterceptor("get",`${casesApiPath}/${caseId}`);
  return jsonApiDeserializer.deserialize(response.data) as Case;
};

export const createCaseApi = async (c: Case) :  Promise<Case>=> {
  const url = `${casesApiPath}`

  const response = await axiosInterceptor(
    "post",
    url,
    {
        "data": {
            "type": "cases",
            "id": c.id,
            "attributes":  dtoFactory.convertCaseToUploadObject(c),
        }
    }
  );
  const caseOut = jsonApiDeserializer.deserialize(response.data) as Case;
  return caseOut;
};

export const updateCaseApi = async (c: Case): Promise<AxiosResponse<any, any>> => {
  const url = `${casesApiPath}/${c.id}`

  return axiosInterceptor(
    "patch",
    url,
    {
      "data": {
        "type": "cases",      
        "id": c.id,
        "attributes": dtoFactory.convertCaseToUploadObject(c),
      }
    }
  );
};

export const deleteCaseApi = async (caseId: string): Promise<AxiosResponse<any,any>>=> {
  return await axiosInterceptor("delete",`${casesApiPath}/${caseId}`);
};

/******* Activities ********/

export const getCaseActivitiesApi = async (
  caseId: string,
  pageNumber?: number,
  pageSize?: number,
  sorter?: string[],
  ) : Promise<[CaseActiviy[], number]>=> {
  
  let url = `${caseActivitiesApiPath}?cid=${caseId}`

  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get", url);
  return [jsonApiDeserializer.deserialize(response.data) as CaseActiviy[], response?.data?.meta?.totalCount];
};

/******* Notes ********/

export const getCaseNotesApi = async (
  caseId: string,
  pageNumber?: number,
  pageSize?: number,
  filters?: string[],
  sorter?: string[],
  ) : Promise<[CaseNote[], number]>=> {
  
  let url = `${caseNotesApiPath}?cid=${caseId}&include=case-attachments`

  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get", url);
  return [jsonApiDeserializer.deserialize(response.data) as CaseNote[], response?.data?.meta?.totalCount];
};

export const getCaseNoteApi = async (caseId: string, caseNoteId: string) : Promise<CaseNote>=> {
  const response = await axiosInterceptor("get",`${caseNotesApiPath}/${caseNoteId}?cid=${caseId}`);
  return jsonApiDeserializer.deserialize(response.data) as CaseNote;
};

export const createCaseNoteApi = async (caseId: string, c: CaseNote) :  Promise<CaseNote>=> {
  const url = `${caseNotesApiPath}?cid=${caseId}`

  const response = await axiosInterceptor(
    "post",
    url,
    {
        "data": {
            "type": "case-notes",
            "id": c.id,
            "attributes":  dtoFactory.convertCaseNoteToUploadObject(c),
        }
    }
  );
  return jsonApiDeserializer.deserialize(response.data) as CaseNote;
};

export const updateCaseNoteApi = async (caseId: string, c: CaseNote): Promise<AxiosResponse<any, any>> => {
  const url = `${caseNotesApiPath}/${c.id}?cid=${caseId}`

  return axiosInterceptor(
    "patch",
    url,
    {
      "data": {
        "type": "case-notes",      
        "id": c.id,
        "attributes": dtoFactory.convertCaseNoteToUploadObject(c),
      }
    }
  );
};

export const deleteCaseNoteApi = async (caseId: string, caseNoteId: string): Promise<AxiosResponse<any,any>>=> {
  return await axiosInterceptor("delete",`${caseNotesApiPath}/${caseNoteId}?cid=${caseId}`);
};

/******* Attachment ********/

export const getCaseAttachmentsApi = async (
  caseId: string,
  pageNumber?: number,
  pageSize?: number,
  filters?: string[],
  sorter?: string[],
  ) : Promise<[CaseAttachment[], number]>=> {
  
  let url = `${caseAttachmentsApiPath}?cid=${caseId}`

  if (pageNumber)
    url += `&page[number]=${pageNumber}`
  if (pageSize)
    url += `&page[size]=${pageSize}`

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  const response = await axiosInterceptor("get", url);
  return [jsonApiDeserializer.deserialize(response.data) as CaseAttachment[], response?.data?.meta?.totalCount];
};

export const getCaseAttachmentApi = async (caseId: string, caseAttachmentId: string) : Promise<CaseAttachment>=> {
  const response = await axiosInterceptor("get",`${caseAttachmentsApiPath}/${caseAttachmentId}?cid=${caseId}`);
  return jsonApiDeserializer.deserialize(response.data) as CaseAttachment;
};

export const deleteCaseAttachmentApi = async (
  caseId: string, 
  caseAttachmentId: string
): Promise<AxiosResponse<any,any>>=> {
  return await axiosInterceptor("delete", `${caseAttachmentsApiPath}/${caseAttachmentId}?cid=${caseId}`);
};

export const uploadCaseAttachmentApi = async (
  caseId: string, 
  formData: any,
  caseNoteId?: string,
): Promise<any>=> {
  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.headers['Content-Type'] = 'multipart/form-data';

  let url = `${caseAttachmentsApiPath}/upload?cid=${caseId}`;

  if (caseNoteId) {
    url += `&nid=${caseNoteId}`
  }

  const response = await axiosInterceptor(
    "post",
    url,
    formData,
    config
  );
  return response.data;
};

export const downloadCaseAttachmentApi = async (
  caseId: string, 
  caseAttachmentId: string,
): Promise<any>=> {

  const config: AxiosConfig = {};
  config.headers = {} as AxiosHeaders;
  config.responseType = 'arraybuffer';

  const response = await axiosInterceptor(
    "get",
    `${caseAttachmentsApiPath}/${caseAttachmentId}/download?cid=${caseId}`,
    null,
    config
  );
  return response.data;
};