import { create } from "zustand"
import { devtools } from "zustand/middleware";

import { CaseStore, PageInfo, Case, CaseTabType } from "types";

import { FilterValue, SorterResult } from "antd/es/table/interface";
import { DataType } from "@textea/json-viewer";
import { convertToApiFilters, convertToApiSorters } from "../utils";
import { 
  createCaseApi, 
  deleteCaseApi, 
  getCaseApi, 
  getCasesApi, 
  updateCaseApi 
} from "api";

export const useCaseStore = create<CaseStore>()(
  devtools((set, get) => ({
    cases: [] as Case[],
    selectedCase: {} as Case,
    pageInfo: {number:1, size: 12, total: 0} as PageInfo,
    filter: {} as Record<string, FilterValue | null>,
    sorter: {} as SorterResult<DataType>,
    fields: [] as string[],
    searchTextMap: new Map<string, string>(),
    totalCount: 0,
    activeTab: CaseTabType.Info,

    getCases: async (all?: boolean) => {
      try {
        const pageInfo = get().pageInfo;
        const filters = get().filter;
        const searches = get().searchTextMap;
        const sorters = get().sorter;
        const fields = get().fields;
        
        const caseFilters = convertToApiFilters(filters, searches)     
        const caseSorters = convertToApiSorters(sorters);
        //default sorting
        if (caseSorters.length == 0) {
          caseSorters.push("-updatedAt");
        }

        const pageNumber = all ? undefined : pageInfo.number;
        const pageSize = all ? undefined : pageInfo.size
        const [cases, totalCount] = await getCasesApi(pageNumber, pageSize, caseFilters, caseSorters);
       
        set((state) => ({
          ...state,
          cases: [...cases],
          totalCount: totalCount,
        }));
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    clearCases: () => {
      set((state) => ({
        ...state,
        cases: [],
        selectedCase: {} as Case,
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    resetCaseStore: () => {
      set((state) => ({
        ...state,
        cases: [],
        selectedCase: {} as Case,
        filter: {} as Record<string, FilterValue | null>,
        sorter: {} as SorterResult<DataType>,
        fields: [] as string[],
        searchTextMap: new Map<string, string>(),
        pageInfo: { number: 1, size: 12, total: 0 } as PageInfo,
      }));
    },

    getCase: async (caseId, refresh) => {
      try {
        let c = get().cases.find((c) => c.id == caseId);
        if (refresh || !c) {
          c = await getCaseApi(caseId);
        }

        set((state) => ({
          ...state,
          selectedCase: c,
        }));
        return c;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    createCase: async (caseIn: Case) => {
      try {
        const c = await createCaseApi(caseIn);
        set((state) => {
          const index = state.cases.findIndex((x) => x.id == c.id);
          index == -1 ? state.cases = [...state.cases, c] : state.cases[index] = c;
          state.selectedCase = c;
          return { ...state }
        });
        return c;
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    updateCase: async (caseIn: Case) => {
      try {
        await updateCaseApi(caseIn);
        set((state) => {
          const idx =  state.cases.findIndex(c => c.id == caseIn.id);
          state.cases[idx] = caseIn;
          return {
            ...state,
            selectedCase: caseIn
          }
        });
      } catch (error: any) {
        console.log(error);
        throw new Error(error);
      }
    },

    deleteCase: async (caseId) => {
      try {
        await deleteCaseApi(caseId);
        set((state) => {
          state.cases = state.cases.filter(c => c.id !== caseId);
          return {
            ...state,
          }
        });
      } catch (error: any) {
        console.error(error);
        throw new Error(error);
      }
    },

    setCurrentPage: (pageNumber?: number, pageSize?: number) => {
      set( (state) => {
        const newPage: PageInfo = {number: pageNumber?pageNumber:1, size: pageSize?pageSize:12, total: state.pageInfo.total};
        return { ...state, pageInfo : newPage};
      });
      return;
    },

    setFilter: (filters?: Record<string, FilterValue | null>) => {
      set( (state) => {
        return { ...state, filter : filters};
      });
      return;
    },

    setSorter: (sorters?: SorterResult<DataType> | SorterResult<DataType>[]) => {
      set( (state) => {
        return { ...state, sorter : sorters};
      });
      return;
    },

    setSearchTextMap: (searches: Map<string, string>) => {
      set((state) => ({
        ...state,
        searchTextMap: new Map<string, string>(searches),
      }));
    },

    setFields: (fields?: string[]) => {
      set( (state) => {
        return { ...state, fields : fields};
      });
      return;
    },

    setActiveTab: (tabType: CaseTabType) => {
      set((state) => ({
        ...state,
        activeTab: tabType,
      }));
    },
  }),
    { name: "CaseStore" }
  )
);
