import { Tooltip as AntdToolTip, Typography, theme } from "antd";
import { metricsIcons } from "assets/icons";
import { SvgIcon } from "components/SvgIcon";
import { useState } from "react";
import {
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { convertToReadbableUnit } from "utility/metrics";
import { TileHeader } from "./TileHeader";

import { TrendData } from "types";
import { getContainerStyle } from "./KpiStyles";
const { Text } = Typography;

export type KpiTrendProps = TrendData & { stretched?: boolean } & {
  id: string;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  onChangeColor?: (color: string) => Promise<void>;
  showOperations?: boolean;
  color?: string;
};

export const KpiTrend = (props: KpiTrendProps) => {
  const [, setLoading] = useState(false);
  const { token } = theme.useToken();
  const containerStyle = getContainerStyle({
    height: "100px",
    minWidth: props.stretched ? "200px" : "150px",
    padding: "5px",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: props.total ? "space-between" : "flex-start",
    backgroundColor: token.colorBgElevated,
    borderBottomRightRadius: token.borderRadiusLG,
    borderTopRightRadius: token.borderRadiusLG,
  });
  const unit = props.primary.unit;
  const trendIcon = metricsIcons[props?.icon as keyof typeof metricsIcons];
  const CustomTooltip = ({
    active,
    payload,
  }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      const value = payload[0].value as number;
      return (
        <AntdToolTip placement="top">
          <Text>{convertToReadbableUnit(unit, value)}</Text>
        </AntdToolTip>
      );
    }
    return null;
  };

  return (
    <div
      id="kpi-trend-container"
      style={{
        height: "100px",
        minWidth: props.stretched ? "200px" : "150px",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
      }}
    >
      <div
        id="kpi-trend-color-code"
        style={{
          background: props.color,
          width: "2%",
          minWidth: "2px",
          maxWidth: "5px",
          borderTopLeftRadius: token.borderRadiusLG,
          borderBottomLeftRadius: token.borderRadiusLG,
        }}
      ></div>
      <div id="kpi-trend-content" style={containerStyle}>
        <TileHeader
          title={props.primary?.text}
          description={props.primary?.text}
          color={props.color}
          showOperations={props.showOperations}
          onRefresh={async () => {
            setLoading(true);
            try {
              await props.onRefresh?.(props.id);
            } finally {
              setLoading(false);
            }
          }}
          onChangeColor={props.onChangeColor}
          onEdit={props.onEdit?.bind(null, props.id)}
          onDelete={props.onDelete?.bind(null, props.id)}
        />
        <div
          id="kpi-trend-body"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            height: "100%",
            alignItems: "center",
            marginLeft: token.marginXXS,
          }}
        >
          <ResponsiveContainer width={"80%"} height={"100%"}>
            <LineChart
              data={props.primary.values.map((x) => {
                return { value: x };
              })}
            >
              <XAxis dataKey={"u"} tick={true} hide>
                <Label value={"time"} position="insideTop" />
              </XAxis>
              <Tooltip content={CustomTooltip} />
              <Line
                dataKey={"value"}
                strokeWidth={"2px"}
                stroke={token.colorPrimary}
                type={"natural"}
                dot={true}
                isAnimationActive={false}
              ></Line>
            </LineChart>
          </ResponsiveContainer>
          <div style={{ marginRight: token.marginXXS }}>
            <SvgIcon size={30} component={trendIcon} />
          </div>
        </div>
        {props.total && props.total.text && props.total.text != "" && (
          <Text
            style={{
              height: "20px",
              marginLeft: token.marginXXS,
              fontSize: token.fontSizeSM,
              color: token.colorSuccess,
            }}
          >
            {`${props.total.text} = ` +
              convertToReadbableUnit(props.total.unit, props.total.value)}
          </Text>
        )}
      </div>
    </div>
  );
};
