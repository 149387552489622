import {
  Avatar,
  Button,
  Descriptions,
  Dropdown,
  Empty,
  notification,
  Row,
  Space,
  Spin,
  theme,
  Typography,
} from "antd";
import { DeleteFilled, EditOutlined, MoreOutlined, DownloadOutlined, DeleteOutlined } from '@ant-design/icons';
import { DataType } from "@textea/json-viewer";
import { SorterResult } from "antd/es/table/interface";

import Modal from "components/Modal";

import { FC, useEffect, useState } from "react";

import { useOrganizationStore, useCaseNotesStore, useUserInfoStore, useCaseAttachmentStore } from "store";
import CollapsePanel from "components/CollapsePanel";
import { CaseMgmtNote } from "./CaseNote";
import { CaseAttachment, CaseNote } from "types";
import SearchInput from "components/SearchInput";
import { SvgIcon } from "components/SvgIcon";
import { commonIcons } from "assets/icons";
import { MenuProps } from "antd/lib";
import RichEditor from "components/RichEditor";
import { getUserDetailsFromJWT } from "utility";
import { getFileIcon } from "./utils";
import FileSaver from "file-saver";

const { Title, Text } = Typography;

export interface CaseMgmtNotesProps {
  caseId: string;
}

export const CaseMgmtNotes: FC<CaseMgmtNotesProps> = ({
  caseId
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
  const [updateNoteModal, setUpdateNoteModal] = useState<{enable: boolean, noteId?: string}>({enable: false});
  const [deleteNoteModal, setDeleteNoteModal] = useState<{enable: boolean, caseNote?: CaseNote}>({enable: false});
  const [deleteAttachmentModal, setDeleteAttachmentModal] = useState<{enable: boolean, caseAttachment?: CaseAttachment}>({enable: false});
  const context = useOrganizationStore((state) => state.context);

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const {
    caseNotes,
    totalCaseNotesCount,
    getCaseNotes,
    deleteCaseNote,
    notesSearchTextMap,
    setNotesSearchTextMap,
    notesSorter,
    setNotesSorter,
  } = useCaseNotesStore((state) => ({
    caseNotes: state.notes,
    totalCaseNotesCount: state.totalCount,
    getCaseNotes: state.getCaseNotes,
    deleteCaseNote: state.deleteCaseNote,
    notesSearchTextMap: state.searchTextMap,
    setNotesSearchTextMap: state.setSearchTextMap,
    notesSorter: state.sorter,
    setNotesSorter: state.setSorter
  }));
  
  const {
    downloadCaseAttachment,
    deleteCaseAttachment,
  } = useCaseAttachmentStore((state) => ({
    downloadCaseAttachment: state.downloadCaseAttachment,
    deleteCaseAttachment: state.deleteCaseAttachment,
  }));


  const loadCaseNotes = async (caseId: string) => {
    try {
      setLoader(true);
      await getCaseNotes(caseId, true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    caseId && caseId !="" && loadCaseNotes(caseId);
  }, [caseId, notesSearchTextMap, notesSorter, context]);

  //Fetch user info for all users linked to case activities
  useEffect(() => {
    try {
      const userIds = [] as string[];
      caseNotes.map((a) => {
        a.userID && a.userID != "" && userIds.push(a.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [caseNotes]);
  

  const deleteCurrentCaseNote = async (caseNote: CaseNote) => {
    try {
      setLoader(true);
      setDeleteNoteModal({enable: false});
      await deleteCaseNote(caseId, caseNote.id);
      notification.success({
        message: "Case note deleted successfully",
        duration: 6,
      });
      loadCaseNotes(caseId);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Error in deleting case note",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const items: MenuProps['items'] = [
    {
      key: 'sort',
      type: 'group',
      label: 'Sort By',
      children: [
        {
          key: 'title',
          label: 'Title',
          children: [
            {
              key: 'title-ascend',
              label: "A-Z"
            },
            {
              key: 'title-descend',
              label: "Z-A"
            }
          ]
        },
        {
          key: 'createdBy',
          label: 'Created At',
          children: [
            {
              key: 'createdAt-ascend',
              label: "Oldest"
            },
            {
              key: 'createdAt-descend',
              label: "Most Recent"
            }
          ]
        },
        {
          key: 'updatedBy',
          label: 'Last Modified',
          children: [
            {
              key: 'updatedAt-ascend',
              label: "Oldest"
            },
            {
              key: 'updatedAt-descend',
              label: "Most Recent"
            }
          ]
        }
      ],
    }
  ];

  const OnDownloadCaseAttachment = async (attahcment: CaseAttachment) => {
    try {
      setLoader(true);
      const responseData = await downloadCaseAttachment(attahcment.caseID, attahcment.id);
      const blob = new Blob([responseData], {type: attahcment.type});
      FileSaver.saveAs(blob, attahcment.name);
      notification.success({
        message: `Case attachment ${attahcment.name} downloaded successfully`,
        duration: 6,
      });
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while downloading attachment...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  const OnDeleteCaseAttachment = async (caseAttachment: CaseAttachment) => {
    try {
      setLoader(true);
      setDeleteAttachmentModal({enable: false});
      await deleteCaseAttachment(caseAttachment.caseID, caseAttachment.id as string);
      notification.success({
        message: `Case attachment file ${caseAttachment.name} deleted successfully`,
        duration: 6,
      });
      loadCaseNotes(caseId);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting file attachment...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  }

  //TBD - support only sort for now
  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key && e.keyPath.length == 2) {
      const sorters: SorterResult<DataType> = {
        order: e.key.split('-')[1] == 'ascend' ? 'ascend' : 'descend',
        field: e.key.split('-')[0],
      };
      setNotesSorter(sorters);
    }
  };

  const attachmentDropDownItems: MenuProps["items"] = [
    {
      key: "download",
      label: "Download",
      icon: <DownloadOutlined />,
    },
    {
      key: "delete",
      label: "Delete",
      //disabled: {caseNote?.userID != getUserDetailsFromJWT()?.userId || !context?.endsWith('_tag')},
      icon: <DeleteOutlined />,
    },
  ];

  const getMenuOptions = (caseNote: CaseNote, attachment: CaseAttachment) => {
    return {
      items: [
        {
          key: "download",
          label: "Download",
          icon: <DownloadOutlined />,
        },
        {
          key: "delete",
          label: "Delete",
          disabled: caseNote?.userID != getUserDetailsFromJWT()?.userId || !context?.endsWith('_tag'),
          icon: <DeleteOutlined />,
        },
      ],
      onClick: (e: any) => {
        e.key == "download" && OnDownloadCaseAttachment(attachment);
        e.key == "delete" && setDeleteAttachmentModal({enable: true, caseAttachment: attachment});
      },
    };
  };

  return (
    <Spin spinning={loader}>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Row justify="space-between">
          <h3>
            Total Notes ({totalCaseNotesCount})
          </h3>
          <Space>
            <div style={{ width: "300px" }}>
              <SearchInput
                placeholder={"search notes by title"}
                onFilter={(e) => setNotesSearchTextMap(new Map<string, string>().set("title", e.target.value)) }
              />
            </div>
            <Dropdown 
              trigger={["click"]} 
              placement={"bottomRight"} 
              menu={{
                items,
                selectable: true,
                onClick: handleMenuClick,
              }}
            >
              <Button
                icon={<SvgIcon size={25} component={commonIcons.filterSortIcon} />}
                onClick={(e) => e.preventDefault() }
              />
            </Dropdown>
            <Button 
              type="primary" 
              size="middle"
              onClick={() => setUpdateNoteModal({enable: true})}
            >
              Add Note
            </Button>
          </Space>
        </Row>
        <div>
          {caseNotes.length > 0 
            ?
            caseNotes.map((caseNote) => 
                <CollapsePanel
                  key={caseNote.id}
                  name={
                    <Space direction="vertical">
                      <Space size={20}>
                        {userInfos[caseNote.userID]?.logoUrl && (
                          <Avatar src={userInfos[caseNote.userID]?.logoUrl} size={"small"}/>
                        )}
                        <Text strong>{caseNote.title}</Text>
                      </Space>
                      <Descriptions size={"small"}>
                        <Descriptions.Item label="Created At">
                          {new Date(caseNote.createdAt).toLocaleTimeString(undefined, { 
                            month: 'short', day: '2-digit', year: 'numeric',
                            hour: '2-digit', minute: '2-digit', hour12: false 
                          })}
                        </Descriptions.Item>
                        {caseNote.createdAt != caseNote.updatedAt
                          &&
                          <Descriptions.Item label="Last Modified">
                            {new Date(caseNote.updatedAt).toLocaleTimeString(undefined, { 
                              month: 'short', day: '2-digit', year: 'numeric',
                              hour: '2-digit', minute: '2-digit', hour12: false 
                            })}
                          </Descriptions.Item>
                        }
                      </Descriptions>
                    </Space>
                  }
                  ghost={false}
                  collapsePanel={true}
                  extraElement={
                    <Space>
                      <Button 
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <EditOutlined style={{ color: token.colorPrimary }}/>
                        }
                        disabled={caseNote?.userID != getUserDetailsFromJWT()?.userId || !context?.endsWith('_tag')}
                        onClick={(e: any) => {
                          e.stopPropagation(); 
                          setUpdateNoteModal({enable: true, noteId: caseNote.id});
                        }}
                      />
                      <Button 
                        type="default"
                        shape="circle"
                        size="small"
                        style={{
                          background: token.colorPrimaryBg,
                        }}
                        icon={
                          <DeleteFilled style={{ color: token.colorPrimary }}/>
                        }
                        disabled={caseNote?.userID != getUserDetailsFromJWT()?.userId || !context?.endsWith('_tag')}
                        onClick={(e: any) => {
                          e.stopPropagation(); 
                          setDeleteNoteModal({enable: true, caseNote: caseNote});
                        }}
                      />
                    </Space>
                  }
                >
                  <RichEditor content={caseNote.content}/>
                  <Space>
                    {caseNote.caseAttachments?.map((attachment) =>
                      <Button
                        key={attachment.sha256}
                        icon={getFileIcon(attachment.type)}
                        style={{cursor: "default"}}
                      >
                        {attachment.name}
                        <Dropdown 
                          placement="bottomLeft" 
                          menu={getMenuOptions(caseNote, attachment)}
                        >
                          <MoreOutlined style={{cursor: "pointer"}}/>
                        </Dropdown>
                      </Button>
                    )}
                  </Space>
                </CollapsePanel>
            )
            :
            <Empty description={"No Notes"} />
          }
        </div>
      </Space>
      {updateNoteModal.enable
        &&
        <CaseMgmtNote 
          caseId={caseId}
          noteId={updateNoteModal.noteId}
          open={updateNoteModal.enable}
          onClose={() => setUpdateNoteModal({enable: false})}
          onSubmit={() => loadCaseNotes(caseId)}
        />
      }
      {deleteNoteModal.enable
        &&
        <Modal
          title="Delete Case Note"
          onClose={() => setDeleteNoteModal({enable: false})}
          open={deleteNoteModal.enable}
          onSubmit={() => deleteNoteModal?.caseNote && deleteCurrentCaseNote(deleteNoteModal.caseNote)}
        >
          {`Are you sure you want to delete case note "${deleteNoteModal?.caseNote?.title}" ?`}
        </Modal>
      }
      {deleteAttachmentModal.enable
        &&
        <Modal
          title="Delete Case Attachment"
          onClose={() => setDeleteAttachmentModal({enable: false})}
          open={deleteAttachmentModal.enable}
          onSubmit={() => deleteAttachmentModal?.caseAttachment && OnDeleteCaseAttachment(deleteAttachmentModal.caseAttachment)}
        >
          {`Are you sure you want to delete case attachment "${deleteAttachmentModal?.caseAttachment?.name}" ?`}
        </Modal>
      }
    </Spin>
  );
};