import { FC, useState, useCallback } from "react";
import { toPng } from "html-to-image";

import PageWrapper from "../../components/PageWrapper";
import { SvgIcon } from "components/SvgIcon";
import { Tabs, type TabsProps, Button, theme, Space, Tooltip } from "antd";
import { CustomDashboardTab } from "components/Dashboard/CustomDashboardTab";
import { HyprEdgeDashboard } from "components/Dashboard/HyprEdgeDashboard";
import { DashboardNew } from "components/Dashboard/DashboardNew";
import { takeScreenShotByElementId } from "utility";
import { dashboardIcons } from "assets/icons";

const items: TabsProps["items"] = [
  {
    key: "hypredge-dashboard",
    label: "HyprEdge Dashboard",
    children: <HyprEdgeDashboard />,
  },
  {
    key: "custom-dashboard",
    label: "My Dashboards",
    children: <CustomDashboardTab />,
  },
];

const DashBoardWrap: FC = () => {
  const { token } = theme.useToken();
  const [newDashboard, setNewDashboard] = useState(false);
  const [activeKey, setActiveKey] = useState("hypredge-dashboard")

  const onScreenShotHandler = () => {
    let dashboardElementId = "dashboard-metrics";
    let dashboardNameSuffix = "hypredge_dashboard";
    if (activeKey == "custom-dashboard"){
      dashboardElementId = "custom-dashboards";
      dashboardNameSuffix = "my-dashboard"
    }
    takeScreenShotByElementId(dashboardElementId, dashboardNameSuffix, token.colorBorderSecondary)
  }


  return (
    <PageWrapper
      content={
        <>
          <Tabs
            defaultActiveKey={activeKey}
            onChange={(key) => setActiveKey(key)}
            items={items}
            style={{ margin: token.margin, width: "100%" }}
            tabBarExtraContent={
              <Space size={0}>
                <Tooltip
                  title={"Add Dashboard"}
                  color={token.colorPrimaryText}
                  placement="top"
                >
                  <Button
                    icon = {<SvgIcon size={30} component={dashboardIcons.dashboardAdd}/>}
                    onClick={() => setNewDashboard(true)}
                  />
                </Tooltip>
                <Tooltip
                  title={"Take Screenshot"}
                  color={token.colorPrimaryText}
                  placement="top"
                >
                  <Button
                    icon = {<SvgIcon size={30} component={dashboardIcons.dashboardScreenshot}/>}
                    onClick={() => onScreenShotHandler()}
                    style={{ margin: token.marginSM }}
                  />
                </Tooltip>
              </Space>
            }
          />
          {newDashboard && (
            <DashboardNew
              open={newDashboard}
              onClose={async () => setNewDashboard(false)}
            />
          )}
        </>
      }
    />
  );
};

export default DashBoardWrap;
