import auth0 from "auth0-js";
import { Deserializer } from 'ts-jsonapi';

// export interface SerializerOptions {
//   [index: string]: any,
//   id: string,
//   attributes: Array<string>,
//   topLevelLinks?: Links,
//   keyForAttribute?: ((attribute: string) => string) | caseOptions,
//   ref?: string | boolean | Function,
//   typeForAttribute?: (attribute: any, user: any) => any,
//   nullIfMissing?: boolean,
//   pluralizeType?: boolean,
//   ignoreRelationshipData?: boolean,
//   relationshipLinks?: Links,
//   relationshipMeta?: RelationshipMeta,
//   dataLinks?: Links,
//   dataMeta?: any,
//   included?: boolean,
//   includedLinks?: Links,
//   embed?: boolean,
//   meta?: any
// }
const relationshipAttributeMapping = new Map<string, string>([
  ["app-subscriptions", "appSubscriptions"],
  ["edge-subscriptions", "edgeSubscriptions"],
  ["case-attachments", "caseAttachments"],
]);


export function keyForAttribute(attribute: string): string {
  const modifiedAttribute = relationshipAttributeMapping.get(attribute);
  if (modifiedAttribute) {
    return modifiedAttribute;
  }
  return attribute;
}
export const jsonApiDeserializer = new Deserializer({
  keyForAttribute: keyForAttribute,
  pluralizeType: false,
});

export const camelCaseEnforcerJsonApiDeserializer = new Deserializer({ keyForAttribute: 'camelCase' });

export const Auth0Config = {
  domain: String(process.env.REACT_APP_AUTH0_DOMAIN),
  clientID: String(process.env.REACT_APP_AUTH0_CLIENT_ID),
  scope: "openid email profile",
  audience: String(process.env.REACT_APP_AUTH0_AUDIENCE),
}

export const auth = new auth0.WebAuth(Auth0Config);
export const auth0Issuer = `https://${Auth0Config.domain}/`;
export const ssoLoginUrl = process.env.REACT_APP_SSO_LOGIN_URL;
export const isProduction = process.env.REACT_APP_ENVIRONMENT === 'production';
export const organizationId = "bda37b9d-3403-442a-9c10-2c52796a5d45";
export const defaultEdgeId = "7ed9d56c-3d82-4d0c-885d-d01e43454389"; 
//SendEmail action for approvals
export const sendEmailActionId = "3c960ec6-4146-469e-b53e-285b4cda0d49";

//base api's path
export const hpredgeApiBasePath=process.env.REACT_APP_HYPREDGE_API_BASE_URL
export const hypredgeSignupApiBasePath=process.env.REACT_APP_HYPREDGE_SIGNUP_API_BASE_URL
export const hypredgeAppBasePath=process.env.REACT_APP_HYPREDGE_APP_BASE_URL
export const umgtBasePath = process.env.REACT_APP_HYPREDGE_UMGT_API_BASE_URL || hpredgeApiBasePath;


//Service - SignUp
export const signupApiBasePath = hypredgeSignupApiBasePath + "/signup/api/v1"
//Resource - tenants
export const tenantsApiPath = signupApiBasePath + "/tenants"
//Password reset
export const passwordResetApiPath = signupApiBasePath + "/resetpassword"
export const ssoUrlApiPath = signupApiBasePath + "/sso-url"

export const usersApiPath = umgtBasePath + "/umgt/api/v1/users"
export const rolesApiPath = umgtBasePath + "/umgt/api/v1/roles"
export const permissionSetsApiPath = umgtBasePath + "/umgt/api/v1/permissionsets"
export const permissionsApiPath = umgtBasePath + "/umgt/api/v1/permissions"
export const resourceApiPath = umgtBasePath + "/umgt/api/v1/resources"
export const subscribersApiPath = umgtBasePath + "/umgt/api/v1/msp/subscriptions"
export const providersApiPath = umgtBasePath + "/umgt/api/v1/msp/providers"
export const clientCredentialsApiPath = umgtBasePath + "/umgt/api/v1/client-credentials"

export const profileApiPath = umgtBasePath + "/umgt/api/v1/profile"
export const userPreferencesApiPath = umgtBasePath + "/umgt/api/v1/preferences"
export const goalsApiPath = umgtBasePath + "/goals/api/v1/goals"
export const tagsApiPath = umgtBasePath + "/umgt/api/v1/tags"
export const dashboardsApiPath = umgtBasePath + "/dashboards/api/v1/dashboards"


//Service - Visibility
export const visibilityApiBasePath = hpredgeApiBasePath + "/visibility/api/v1"
//Resource - activities
export const activitiesApiPath = visibilityApiBasePath + "/activities"
//Resource - metrics
export const metricsApiPath = visibilityApiBasePath + "/metrics"
export const metricsDefinitionsApiPath = visibilityApiBasePath + "/metric-definitions"
export const metricsLayoutsApiPath = visibilityApiBasePath + "/metric-layouts"

// service settings
export const settingsApiPath = hpredgeApiBasePath + "/settings/api/v1"
//Service - App
export const appApiBasePath = hpredgeApiBasePath + "/app/api/v1"
//Resource - apps
export const appsApiPath = appApiBasePath + "/apps"
//Resource - app-edge
export const appEdgeApiPath = appApiBasePath + "/app-edge/recommend"
//Resource - regions
export const appRegionsApiPath = appApiBasePath + "/app-edge/regions"
//Resource - app-subscription
export const appSubscriptionsApiPath = appApiBasePath + "/app-subscriptions"

//Service - Edge
export const edgeApiBasePath = hpredgeApiBasePath + "/edge/api/v1"
//Resource - edges
export const edgesApiPath = edgeApiBasePath + "/edges"
//Resource - edge-subscriptions
export const edgeSubscriptionsApiPath = edgeApiBasePath + "/edge-subscriptions"
//Resource - edge-rbac
export const edgeRbacApiPath = edgeApiBasePath + "/edge-rbac"

//Service - Search
export const searchApiBasePath = hpredgeApiBasePath + "/search/api/v1"
//Resource - search-x
export const searchxRunApiPath = searchApiBasePath + "/search-x/run"
//Resource - search-runs
export const searchRunsApiPath = searchApiBasePath + "/search-runs"
//Resource - recent-searches
export const recentSearchesApiPath = searchApiBasePath + "/recent-searches"
//Resource - popular-searches
export const popularSearchesApiPath = searchApiBasePath + "/popular-searches"
//Resource - searches
export const searchesApiPath = searchApiBasePath + "/searches"
//Resource - search-run-actions
export const searchRunActionsApiPath = searchApiBasePath + "/search-run-actions"
//Resource - artifacts
export const artifactsApiPath = searchApiBasePath + "/artifacts"
//Resource - artifact-providers
export const artifactProvidersApiPath = searchApiBasePath + "/artifact-providers"
//Resource - policies
export const searchPoliciesApiPath = searchApiBasePath + "/policies"


//Service - Workflow
export const workflowApiBasePath = hpredgeApiBasePath + "/workflow/api/v1"
//Resource - triggers
export const triggersApiPath = workflowApiBasePath + "/triggers"
//Resource - actions
export const actionsApiPath = workflowApiBasePath + "/actions"
//Resource - operators
export const operatorsApiPath = workflowApiBasePath + "/operators"
//Resource - workflows
export const workflowsApiPath = workflowApiBasePath + "/workflows"
//Resource - workflow-runs
export const workflowRunsApiPath = workflowApiBasePath + "/workflow-runs"
//Resource - trigger-providers
export const triggerProvidersApiPath = workflowApiBasePath + "/trigger-providers"
//Resource - action-providers
export const actionProvidersApiPath = workflowApiBasePath + "/action-providers"
//Resource - policies
export const workflowPoliciesApiPath = workflowApiBasePath + "/policies"

//Service - Approval
export const approvalApiBasePath = hpredgeApiBasePath + "/approval/api/v1"
//Resource - approvals
export const approvalsApiPath = approvalApiBasePath + "/approvals"

//Service - Casemgmt
export const casemgmtApiBasePath = hpredgeApiBasePath + "/casemgmt/api/v1"
//Resource - cases
export const casesApiPath = casemgmtApiBasePath + "/cases"
//Resource - case-activities
export const caseActivitiesApiPath = casemgmtApiBasePath + "/case-activities"
//Resource - case-notes
export const caseNotesApiPath = casemgmtApiBasePath + "/case-notes"
//Resource - case-attachments
export const caseAttachmentsApiPath = casemgmtApiBasePath + "/case-attachments"

export const contentApiBasePath = hpredgeApiBasePath + "/umgt/api/v1"

export const tenantHeaderKeyID             = "x-zstreams-tenant-id"