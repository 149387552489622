import {
  Spin,
  Typography,
  theme,
  Badge,
  Space,
  Avatar,
  Divider,
  Tag
} from "antd";
import {
  FlagOutlined
} from "@ant-design/icons";

import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {  useOrganizationStore, useSettingsStore, useUserInfoStore, useCaseStore } from "store";
import { notification } from 'utility/notification';

import { 
  Case, 
  CaseCategoriesMap, 
  CasePriority, 
  CasePriorityMap, 
  CaseSeverity, 
  CaseSeverityMap, 
  CaseSourceMap, 
  CaseStatus, 
  CaseStatusMap,
  CaseTabType,
  dateTimeFormatOptions
} from "types";
import { EntitiesDisplay } from "components/EntitiesDisplay";
import { ColumnsType } from "antd/es/table";
import { TableProps } from "antd/lib/table";
import { DataType } from "@textea/json-viewer";
import { HLink } from "components/Link";
import { capitalizeFirstWord, getTagColor } from "utility";
import CaseCreateModal from "./CaseCreate";
import { BaseType } from "antd/es/typography/Base";

const { Title, Text } = Typography;

export const CaseMgmtCases: FC = () => {
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const useDivider = useSettingsStore((state) => state.useDividerBelowHeader);
  const [loader, setLoader] = useState(false);
  const [createCaseModal, setCreateCaseModal] = useState(false);

  const context = useOrganizationStore((state) => state.context);

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const {mspEnabled, tags } = useOrganizationStore((state) => ({
    mspEnabled: state.mspEnabled,
    tags: state.tags,
  }));

  const {
    cases,
    totalCasesCount,
    casePage,
    setCurrentPage,
    caseFilter,
    setCaseFilter,
    caseSorter,
    setCaseSorter,
    getCases,
    clearCases,
    createCase,
    deleteCase,
    caseSearchTextMap,
    setCaseSearchTextMap,
    setActiveTab
  } = useCaseStore((state) => ({
    cases: state.cases,
    totalCasesCount: state.totalCount,
    casePage: state.pageInfo,
    setCurrentPage: state.setCurrentPage,
    caseFilter: state.filter,
    setCaseFilter: state.setFilter,
    caseSorter: state.sorter,
    setCaseSorter: state.setSorter,
    getCases: state.getCases,
    clearCases: state.clearCases,
    createCase: state.createCase,
    deleteCase: state.deleteCase,
    caseSearchTextMap: state.searchTextMap,
    setCaseSearchTextMap: state.setSearchTextMap,
    setActiveTab: state.setActiveTab
  }));

  const loadCases = async () => {
    try {
      setLoader(true);
      //clearCases(); 
      await getCases();
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    return () => {
      clearCases();
    };
  }, []);

  useEffect(() => {
    loadCases();
  }, [caseFilter, caseSorter, caseSearchTextMap, casePage, context]);

  //Fetch user info for all users linked to cases
  useEffect(() => {
    try {
      const userIds = [] as string[];
      cases.map((c) => {
        c.assigneeID && c.assigneeID != "" && userIds.push(c.assigneeID);
        c.userID && c.userID != "" && userIds.push(c.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [cases]);

  const OnCreateCase = async (values: any) => {
    try {
      setLoader(true);
      const caseIn = {
        name: values.name,
        description: values.description,
        status: CaseStatus.New,
        category: values.category,
        severity: values.severity,
        assigneeID: values.assigneeID,
        tags: values.tags,
      } as Case;
      const caseOut = await createCase(caseIn);
      notification.success({
        message: `Case "${caseIn.name}" created successfully`,
        duration: 6,
      });
      const caseId = caseOut.id;
      navigate(`/cases/${caseId}`);
      setActiveTab(CaseTabType.Notes);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while creating case...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const OnDeleteCases = async (items: any[]) => {
    try {
      setLoader(true);
      const cases = items as Case[];
      await Promise.all(
        cases.map(async (c) => {
          await deleteCase(c.id);
          notification.success({
            message: `Case ${c.name} deleted successfully`,
            duration: 6,
          });
        })
      );
      
      await getCases();
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Something went wrong while deleting cases...!",
        duration: 6,
      });
    } finally {
      setLoader(false);
    }
  };

  const getCaseStatus= (caseStatus: CaseStatus) => {
    switch (caseStatus) {
      case CaseStatus.New:
        return <Badge status="error" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
      case CaseStatus.InProgress:
        return <Badge status="processing" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
      case CaseStatus.OnHold:
        return <Badge status="warning" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
      case CaseStatus.Resolved:
        return <Badge status="success" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
      case CaseStatus.Closed:
        return <Badge status="default" text={<Text>{CaseStatusMap.get(caseStatus)}</Text>} />;
    }
  }

  const getCaseSeverity= (caseSeverity: CaseSeverity) => {
    let color = ""
    switch (caseSeverity) {
      case CaseSeverity.Informational:
        color = "#0aab3d";
        break;
      case CaseSeverity.Low:
        color = "#4ec1f2";
        break;
      case CaseSeverity.Medium:
        color = "#f5ce0f";
        break;
      case CaseSeverity.High:
        color = "#f2885e";
        break;
      case CaseSeverity.Critical:
        color = "#d60d0d";
        break;
    }
    return <Tag icon={<FlagOutlined />} color={color}>{CaseSeverityMap.get(caseSeverity)}</Tag>
  }

  const getCasePriority= (casePriority: CasePriority) => {
    let type: BaseType | undefined = undefined
    switch (casePriority) {
      case CasePriority.P1:
        type = "danger";
        break;
      case CasePriority.P2:
        type = "danger";
        break;
      case CasePriority.P3:
        type = "warning";
        break;
      case CasePriority.P4:
        type = "secondary";
        break;
      case CasePriority.P5:
        type = "secondary";
        break;
    }
    return <Text type={type}>{CasePriorityMap.get(casePriority)}</Text>;
  }


  const columns: ColumnsType<Case> = [   
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      render: (_, c) => (
        <HLink onClick={() => navigate("/cases/" + c.id)} text={c.number}></HLink> 
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: '20%',
      sorter: true,
      sortDirections: ['ascend', 'descend', null],
      render: (name) => {
        return (
          <Text>{name}</Text>
        );
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      filters: Array.from(CaseCategoriesMap, ([category, value]) => ({ text: value, value: category })),
      filterSearch: true,
      filteredValue: caseFilter["category"],
      render: (category) => {
        return (
          <Text>{CaseCategoriesMap.get(category)}</Text>
        );
      },
    },
    {
      title: "Tag",
      dataIndex: "tenantID",
      key: "tenantID",
      responsive: ['xxl'],
      hidden: !mspEnabled,
      filterSearch: true,
      filters: tags.map((tag) => ({ text: tag.value, value: tag.id.split("_")[0] })),
      filteredValue: caseFilter["tenantID"],
      render: (tenantID: string) => {
        const tag = tags?.find((tag) => (tag.id == `${tenantID}_tag`));
        return tag ? <Tag color={getTagColor(tag.id)}>{tag.value}</Tag> : <></>
      },
    },
    {
      title: "Severity",
      dataIndex: "severity",
      key: "severity",
      filters: Array.from(CaseSeverityMap, ([tag, value]) => ({ text: value, value: tag })),
      filterSearch: true,
      filteredValue: caseFilter["severity"],
      render: (severity) => {
        return (
          getCaseSeverity(severity)
        );
      },
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      filters: Array.from(CasePriorityMap, ([tag, value]) => ({ text: value, value: tag })),
      filterSearch: true,
      filteredValue: caseFilter["priority"],
      render: (priority) => {
        return (
          getCasePriority(priority)
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: Array.from(CaseStatusMap, ([tag, value]) => ({ text: value, value: tag })),
      filterSearch: true,
      filteredValue: caseFilter["status"],
      render: (status) => {
        return (
          getCaseStatus(status)
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      responsive: ['xxl'],
      filters: Array.from(CaseSourceMap, ([tag, value]) => ({ text: value, value: tag })),
      filterSearch: true,
      filteredValue: caseFilter["source"],
      render: (status) => {
        return (
          <Text >{capitalizeFirstWord(status)}</Text>
        );
      },
    },
    {
      title: "Assignee",
      dataIndex: "assigneeID",
      key: "assigneeID",
      render: (assigneeID: string) => (
        <Space>
          {userInfos[assigneeID]?.logoUrl && (
            <Avatar src={userInfos[assigneeID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[assigneeID]?.email} text={`${userInfos[assigneeID]?.firstName} ${userInfos[assigneeID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Reporter",
      dataIndex: "userID",
      key: "userID",
      responsive: ['xxl'],
      render: (userID: string) => (
        <Space>
          {userInfos[userID]?.logoUrl && (
            <Avatar src={userInfos[userID]?.logoUrl} />
          )}
          <HLink href={"mailto:" + userInfos[userID]?.email} text={`${userInfos[userID]?.firstName} ${userInfos[userID]?.lastName}`} />
        </Space>
      ),
    },
    {
      title: "Reported",
      dataIndex: "createdAt",
      key: "createdAt",
      responsive: ['xxl'],
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (createdAt: string) => (
        new Date(createdAt).toLocaleTimeString(
          undefined,
          dateTimeFormatOptions
        )
      ),
    },
    {
      title: "Closed",
      dataIndex: "closedAt",
      key: "closedAt",
      responsive: ['xxl'],
      sorter: true,
      sortDirections: ['descend', 'ascend', null],
      render: (_, c) => {
        return (
          c.status == CaseStatus.Closed ? (
            new Date(c.closedAt).toLocaleTimeString(
              undefined,
              dateTimeFormatOptions
            )
          ): (
            <Text>N/A</Text>
          )
        )
      }
    },
    {
      title: "Labels",
      dataIndex: "tags",
      key: "tags",
      responsive: ['xxl'],
      //filterSearch: true,
      //filters: Array.from(AppTagsMap, ([tag, value]) => ({ text: value, value: tag })),
      //filteredValue: appFilter["tags"],
      render: (tags: string[]) => {
        return (
          <Space size={1} style={{ display: "flex" }}>
            {tags?.map((tag) => 
              <Tag
                key={tag}
                color={getTagColor(tag)}
              >
                {tag}
              </Tag>
            )}
          </Space>
        );
      },
    },
  ]

  const onChange: TableProps<DataType>['onChange'] = (pagination, filters, sorter)  => {
    setCaseSorter(sorter);
    setCaseFilter(filters);
    setCurrentPage();
  }

  return (
    <div style={{ margin: token.margin, width: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: "100%",
          height: "60px",
          paddingRight: token.padding,
          alignItems: "center",
        }}
      >
        <Title level={3}>Cases</Title> 
      </div>
      {useDivider && <Divider />}
      <Spin spinning={loader}>
        <EntitiesDisplay
          header={"Cases"}
          dataSource={cases as any[]}
          columns={columns}
          pageNumber={casePage.number}
          pageSize={casePage.size}
          total={totalCasesCount}
          onChange={onChange}
          onSearch={(text: string) => {
            setCurrentPage();
            setCaseSearchTextMap(new Map<string, string>().set("name", text));
          }}
          searchPlaceholder={"search cases by name"}
          searchText={caseSearchTextMap?.get("name")}
          onPageChange={(pageNumber: number, pageSize: number)  => setCurrentPage(pageNumber, pageSize)}
          actions={
            [
              {
                key: "create", 
                label: "Create", 
                enable: () => true, 
                onClick: () => setCreateCaseModal(true)
              },
              {
                key: "delete", 
                label: "Delete", 
                enable: (itemIds) => {
                  if (itemIds && itemIds.length) {
                    return itemIds.every((id) => cases.find((c) => c.id == id && `${c.tenantID}_tag` == context));
                  } else {
                    return false;
                  }
                }, 
                hidden: !context?.endsWith('_tag'),
                showWarn: true,
                onClick: (value) => {
                  const itemIds = value as string[];
                  const items = cases.filter((c) => itemIds.find((id) => id == c.id))
                  if (items) {
                    OnDeleteCases(items);
                  }
                }
              }
            ]
          }
        />  
        {createCaseModal 
          &&
          <CaseCreateModal
            open={createCaseModal}
            onClose={() => setCreateCaseModal(false)}
            onSubmit={(values) => OnCreateCase(values)}
          />
        } 
      </Spin>
    </div>
  );
};
