import {
  Button,
  Empty,
  Modal,
  Space,
  Spin,
  Typography,
  notification,
  theme,
} from "antd";
import { toPng } from "html-to-image";
import { KpiGrid } from "components/Metrics/KpiGrid";
import { useEffect, useState } from "react";
import { Layout } from "react-grid-layout";
import { useDashboardStore, useGoalStore } from "store";
import { SvgIcon } from "components/SvgIcon";
import {
  Dashboard,
  Metric,
  MetricInterval,
  MiniTileData,
  TrendData,
  Widget,
  WidgetType,
} from "types";

import { WidgetEdit } from "./WidgetEdit";
import { takeScreenShotByElementId } from "utility";
import ControlButton from "components/ControlButton/controlButton";
import { dashboardIcons } from "assets/icons";

const { Text } = Typography;

const createTrendData = (widget: Widget, data: number[]): TrendData => {
  return {
    primary: {
      text: widget.title,
      values: data,
      unit: widget.unit,
    },
    average: {
      text: "Average",
      value: data.length ? data.reduce((a, b) => a + b, 0) / data.length : 0,
      unit: widget.unit,
    },
    total: {
      text: "Total",
      value: data.length ? data[data.length - 1] : 0,
      unit: widget.unit,
    },
    interval: MetricInterval.TWELVEHOURS,
  };
};

const createMiniTileData = (widget: Widget, data: number[]): MiniTileData => {
  return {
    primary: {
      text: widget.title,
      value: data.length ? data[data.length - 1] : 0,
      unit: widget.unit,
    },
    interval: MetricInterval.TWELVEHOURS,
  };
};
export interface CustomDashboardProps {
  dashboard: Dashboard;
}

export const CustomDashboard = (props: CustomDashboardProps) => {
  const [busy, setBusy] = useState(false);
  const [editLayout, setEditLayout] = useState(false);
  const [modifiedLayout, setModifiedLayout] = useState<Layout[]>([]);
  const [idToDelete, setIdToDelete] = useState<string | null>(null);
  const [widgetDeleteInProgress, setWidgetDeleteInProgress] = useState(false);
  const [dashboardDeleteInProgress, setDashboardDeleteInProgress] =
    useState(false);
  const [dashboardDeleteFlag, setDashboardDeleteFlag] = useState(false);

  const { token } = theme.useToken();
  const setLayout = useDashboardStore((state) => state.setLayout);
  const updateDashboardProperties = useDashboardStore(
    (state) => state.updateDashboardProperties
  );
  const deleteWidget = useDashboardStore((state) => state.deleteWidget);
  const updateWidget = useDashboardStore((state) => state.updateWidget);
  const listGoals = useGoalStore((state) => state.listGoals);
  const addWidget = useDashboardStore((state) => state.addWidget);
  const deleteDashboard = useDashboardStore((state) => state.deleteDashboard);

  const goalMetrics = useGoalStore((state) => state.goalMetrics);

  const [addMetric, setAddMetric] = useState(false);
  const [editMetric, setEditMetric] = useState<string | null>(null);
  const [displayedMetrics, setDisplayedMetrics] = useState<{
    [k: string]: Metric;
  }>({});

  useEffect(() => {
    /* For each widget in dashboards, get the items from goalMetrics, and make it into a named metric  */
    const metrics: { [k: string]: Metric } = {};
    props.dashboard.widgets.forEach((widget) => {
      const metric = goalMetrics[widget.id];
      switch (widget.type) {
        case WidgetType.Trend:
          metrics[widget.id] = {
            id: widget.id,
            widgetType: WidgetType.Trend,
            data: createTrendData(widget, metric || []),
          };
          break;
        case WidgetType.MiniTile:
          metrics[widget.id] = {
            id: widget.id,
            widgetType: WidgetType.MiniTile,
            data: createMiniTileData(widget, metric || []),
          };
          break;
        default:
          console.log("Unknown widget type");
          break;
      }
    });
    setDisplayedMetrics(metrics);
  }, [goalMetrics]);

  const updateName = async (name: string) => {
    try {
      setBusy(true);
      await updateDashboardProperties(
        props.dashboard.id,
        name,
        props.dashboard.description,
        props.dashboard.color
      );
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Failed to update dashboard name",
        duration: 6,
      });
    } finally {
      setBusy(false);
    }
  };

  const updateDescription = async (description: string) => {
    try {
      setBusy(true);
      await updateDashboardProperties(
        props.dashboard.id,
        props.dashboard.name,
        description,
        props.dashboard.color
      );
    } catch (e) {
      console.error(e);
      notification.error({
        message: "Failed to update dashboard description",
        duration: 6,
      });
    } finally {
      setBusy(false);
    }
  };

  const onScreenShotHandler = () => {
    takeScreenShotByElementId(props.dashboard.id, props.dashboard.name, token.colorBorderSecondary)
  }

  return (
    <Spin spinning={busy}>
      <div
        id={`custom-metrics-dashboard-${props.dashboard.id}`}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: token.padding,
          marginBottom: token.margin,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Space direction="vertical">
            <Text
              editable={{ onChange: updateName }}
              style={{
                fontSize: token.fontSizeHeading4,
              }}
            >
              {props.dashboard.name}
            </Text>
            <Text editable={{ onChange: updateDescription }}>
              {props.dashboard.description}
            </Text>
          </Space>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <ControlButton
              displayName={"Delete Dashboard"}
              svgComponent={
                <SvgIcon
                  onClick={() => setDashboardDeleteFlag(true)}
                  component={dashboardIcons.dashboardDelete}
                />
              }
              hoverable={true}
            />
            {!editLayout && (
              <ControlButton
                displayName={"Add Metric"}
                svgComponent={
                  <SvgIcon
                  onClick={() => setAddMetric(true)}
                    component={dashboardIcons.metricAdd}
                  />
                }
                hoverable={true}
              />
            )}
            {props.dashboard.widgets?.length ? (
              <ControlButton
                displayName={editLayout ? "Save Layout" : "Edit Layout"}
                svgComponent={
                  <SvgIcon
                    onClick={() => {
                      if (editLayout) {
                        setLayout(props.dashboard.id, modifiedLayout);
                        setModifiedLayout([]);
                      }
                      setEditLayout(!editLayout);
                    }}
                    component={editLayout ? dashboardIcons.layoutSave : dashboardIcons.layoutEdit}
                  />
                }
                hoverable={true}
              />
            ) : null}
            <ControlButton
              displayName={"Take ScreenShot"}
              svgComponent={
                <SvgIcon
                  onClick={() => onScreenShotHandler()}
                  component={dashboardIcons.metricScreenshot}
                />
              }
              hoverable={true}
            />
          </div>
        </div>
        {props.dashboard.widgets?.length ? (
          <KpiGrid
            dashboard={props.dashboard}
            metrics={displayedMetrics}
            isDraggable={editLayout}
            isDroppable={editLayout}
            showWidgetOperations={true}
            onLayoutChange={async (layouts: Layout[]) => {
              setModifiedLayout(layouts);
            }}
            onRefresh={!editLayout ? listGoals : undefined}
            onWidgetColorChange={async (id: string, color: string) => {
              updateWidget(props.dashboard.id, id, { color });
            }}
            onEdit={async (id: string) => {
              if (!editLayout) setEditMetric(id);
            }}
            onDelete={async (id: string) => {
              if (!editLayout) setIdToDelete(id);
            }}
          />
        ) : (
          <Empty />
        )}
        {addMetric && (
          <WidgetEdit
            dashboardId={props.dashboard.id}
            isAdd={true}
            onClose={async () => setAddMetric(false)}
            onSave={async (widget: Widget) => {
              try {
                setBusy(true);
                widget.position = props.dashboard.widgets.length;
                await addWidget(props.dashboard.id, widget);
                await listGoals();
                setAddMetric(false);
              } finally {
                setBusy(false);
              }
              /* Add a new item to layout, trigger a on layout change, save layout and widget */
            }}
          />
        )}
        {editMetric && (
          <WidgetEdit
            dashboardId={props.dashboard.id}
            isAdd={false}
            widget={props.dashboard.widgets.find((w) => w.id === editMetric)}
            onClose={async () => setEditMetric(null)}
            onSave={async (widget: Widget) => {
              await updateWidget(props.dashboard.id, editMetric, widget);
              await listGoals();
              setEditMetric(null);
            }}
          />
        )}
        {idToDelete && (
          <Modal
            title="Confirm delete"
            onClose={() => setIdToDelete(null)}
            onCancel={() => setIdToDelete(null)}
            open={idToDelete != null}
            onOk={async () => {
              try {
                setWidgetDeleteInProgress(true);
                await deleteWidget(props.dashboard.id, idToDelete);
                setIdToDelete(null);
              } finally {
                setWidgetDeleteInProgress(false);
              }
            }}
          >
            <Spin spinning={widgetDeleteInProgress}>
              <Text>Are you sure you want to delete this item?</Text>
            </Spin>
          </Modal>
        )}
        <Modal
          title="Confirm delete"
          onClose={() => setDashboardDeleteFlag(false)}
          onCancel={() => setDashboardDeleteFlag(false)}
          open={dashboardDeleteFlag}
          onOk={async () => {
            try {
              setDashboardDeleteInProgress(true);
              await deleteDashboard(props.dashboard.id);
            } finally {
              setDashboardDeleteFlag(false);
              setDashboardDeleteInProgress(false);
            }
          }}
        >
          <Spin spinning={dashboardDeleteInProgress}>
            <Text>Are you sure you want to delete this dashboard?</Text>
          </Spin>
        </Modal>
      </div>
    </Spin>
  );
};
