import {
    FileImageOutlined,
    FileOutlined,
    FilePdfOutlined,
    FileExcelOutlined,
    FileGifOutlined,
    FileJpgOutlined,
    FileMarkdownOutlined,
    FilePptOutlined,
    FileTextOutlined,
    FileWordOutlined,
    FileZipOutlined,
  } from '@ant-design/icons';

export const getFileIcon = (fileType: string) => {
    switch (fileType) {
      case "image/svg+xml":
      case "image/png":
        return <FileImageOutlined />
      case "image/gif":
        return <FileGifOutlined />
      case "image/jpeg":
        return <FileJpgOutlined />
      case "application/pdf":
       return <FilePdfOutlined />
      case "application/vnd.ms-excel":
        return <FileExcelOutlined />
      case "text/markdown":
        return <FileMarkdownOutlined />
      case "application/vnd.ms-powerpoint":
        return <FilePptOutlined />
      case "text/plain":
        return <FileTextOutlined />
      case "application/msword":
        return <FileWordOutlined />
      case "application/gzip":
        return <FileZipOutlined />
      default:
        return <FileOutlined />
    }
  }
