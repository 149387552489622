// TBD - catagories should be moved to backend and fetch based on domain like security etc..

  export const CaseCategoriesMap = new Map<string,string>([
    ["threats", "Threats"],
    ["riskAndExposure", "Risk & Exposure"],
    ["investigations", "Investigations"],
    ["incidents", "Incidents"],
    ["employeeTrainingAndDevelopment", "Employee Training & Development (Cyber)"],
    ["auditAndCompliance", "Audit & Compliance"]
  ]);
