import { Empty, Spin, Typography, theme } from "antd";

import { metricsIcons } from "assets/icons";
import { ItemsGroup } from "components/ItemsGroup";
import { SvgIcon } from "components/SvgIcon";
import { UserAvatarGroup } from "components/UserAvatarGroup";
import { useState } from "react";

import { useRbacStore, useSettingsStore } from "store";
import { MiniTileData } from "types";
import { TileHeader } from "./TileHeader";

const { Text } = Typography;

export type KpiMiniTileProps = MiniTileData & { stretched?: boolean } & {
  id: string;
  onEdit?: (id: string) => Promise<void>;
  onDelete?: (id: string) => Promise<void>;
  onRefresh?: (id: string) => Promise<void>;
  onChangeColor?: (color: string) => Promise<void>;
  showOperations?: boolean;
  color?: string;
};

export const KpiMiniTile = (props: KpiMiniTileProps) => {
  const [loading, setLoading] = useState(false);
  const { token } = theme.useToken();
  const { mode } = useSettingsStore((state) => ({
    mode: state.lightMode,
  }));
  const { users, roles } = useRbacStore((state) => ({
    users: state.users,
    roles: state.roles,
  }));
  const tileIcon = metricsIcons[props?.icon as keyof typeof metricsIcons];

  const natureToColor = (nature: string | undefined): string => {
    if (!nature) return mode ? "#282876" : token.colorTextLightSolid;
    switch (nature) {
      case "error":
        return token.colorError;
      case "success":
        return token.colorSuccess;
      case "warning":
        return token.colorWarning;
      default:
        return mode ? "#282876" : token.colorTextLightSolid;
    }
  };

  const getCustomItem = () => {
    switch (props.id) {
      case "users-total":
        return (
          <UserAvatarGroup size={"default"} userIds={Object.keys(users)} />
        );
      case "roles-total":
        return (
          <ItemsGroup
            items={Object.values(roles).map((roleDto) => ({
              id: roleDto.id,
              name: roleDto.name,
              description: roleDto.name,
            }))}
            size={"default"}
          />
        );
      default: {
        if (!props.tertiary) return <></>;
        if (!props.tertiary.items) return <></>;
        return (
          <ItemsGroup
            items={props.tertiary.items.map((x) => ({
              id: x.id,
              name: x.name,
              description: x.name,
            }))}
            size={"default"}
          />
        );
      }
    }
  };

  return (
    <div
      id="kpi-mini-tile-container"
      style={{
        height: "100px",
        minWidth: props.stretched ? "200px" : "150px",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
      }}
    >
      <div
        id="kpi-mini-tile-color-code"
        style={{
          background: props.color,
          width: "2%",
          minWidth: "2px",
          maxWidth: "5px",
          borderTopLeftRadius: token.borderRadiusLG,
          borderBottomLeftRadius: token.borderRadiusLG,
        }}
      ></div>
      <div
        id="kpi-mini-tile-content"
        style={{
          padding: "5px",
          display: "flex",
          flexDirection: "column",
          justifyContent: props.secondary ? "space-between" : "flex-start",
          flexGrow: 1,
          borderBottomRightRadius: token.borderRadiusLG,
          borderTopRightRadius: token.borderRadiusLG,
        }}
      >
        <TileHeader
          showOperations={props.showOperations}
          title={props.primary?.text}
          description={props.primary?.text}
          color={props.color}
          onRefresh={async () => {
            setLoading(true);
            try {
              await props.onRefresh?.(props.id);
            } finally {
              setLoading(false);
            }
          }}
          onChangeColor={props.onChangeColor}
          onEdit={props.onEdit?.bind(null, props.id)}
          onDelete={props.onDelete?.bind(null, props.id)}
        />
        <Spin spinning={loading}>
          <div
            id="kpi-mini-tile-body"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              height: "30px",
              marginLeft: token.marginXXS,
              alignItems: "center",
            }}
          >
            <Text
              strong
              ellipsis
              style={{
                fontSize: props.secondary
                  ? token.fontSizeHeading3
                  : token.fontSizeHeading1,
                color: mode ? "#282876" : token.colorTextLightSolid,
                maxWidth: props.stretched ? "275px" : "250px",
              }}
            >
              {props.primary.value ?? <Empty />}
              <span style={{ fontSize: token.fontSizeHeading4 }}>
                {`  ${props.primary.unit ?? ""}`}
              </span>
            </Text>
            {getCustomItem()}
            <SvgIcon size={30} component={tileIcon} />
          </div>
        </Spin>
        {props.secondary && (
          <Text
            style={{
              height: "20px",
              marginLeft: token.marginXXS,
              fontSize: token.fontSizeSM,
              color: natureToColor(props.secondaryNature),
            }}
          >
            {`${props.secondary.value}${props.secondary.unit} ${props.secondary.text}`}
          </Text>
        )}
      </div>
    </div>
  );
};
