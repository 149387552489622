import {
  Space,
  Spin,
  notification,
  theme,
  Form,
  Input,
  Select,
  Button,
  Row,
  Col,
} from "antd";

import { FC, useEffect, useState } from "react";

import { useRbacStore, useCaseStore } from "store";
import { Case, CaseCategoriesMap, CasePriorityMap, CaseSeverityMap, CaseStatusMap } from "types";
import { capitalizeWords } from "utility";
import { FieldLabel } from "components/FieldLabel";
import TextArea from "antd/es/input/TextArea";
import { CaseMgmtNote } from "./CaseNote";

export interface CaseMgmtInfoProps {
  caseLoader: boolean;
  caseIn: Case;
}

export const CaseMgmtInfo: FC<CaseMgmtInfoProps> = ({
  caseLoader,
  caseIn
}) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const users = useRbacStore((state) => state.users);

  const {
    updateCase
  } = useCaseStore((state) => ({
    updateCase: state.updateCase
  }));
  
  useEffect(() => {
    setLoader(caseLoader);
  }, [caseLoader]);

  useEffect(() => {
    caseIn && form.setFieldsValue(caseIn);
  }, [caseIn]);

  const updateCurrentCase = async () => {
    let c: Case;
    let hasValidationError = false;

    try {
      setLoader(true);
      try {
        c = await form.validateFields();
      } catch (error) {
        hasValidationError = true;
        throw error;
      }
      c.id = caseIn.id;
      await updateCase(c);
      setLoader(false);
      notification.success({
        message: `Case "${c.name}" updated successfully`,
        duration: 6,
      });
    } catch (error) {
      if (!hasValidationError) {
        notification.error({
          message: `Failed to update case, ${JSON.stringify(error)}`,
          duration: 6,
        });
      }
    } finally {
      setLoader(false);
    }
  };

  const getRules = (name: string, required: boolean): any[] => {
    const rules = [];
    if (required) {
      rules.push({ required: true, message: `${name} is required!` })
    }
    return rules;
  };

  return (
    <>
      <Form
        form={form}
        name="caseForm"
        layout="vertical"
        autoComplete="off"
      >
        <Spin spinning={loader}>
          <Space size={0} direction="vertical" style={{ display: 'flex' }}>  
            <Row justify="end" style={{ marginBottom: token.margin }}>
              <Button 
                type="primary" 
                htmlType="submit"
                size="middle"
                onClick={() => form
                  .validateFields()
                  .then(() => setAddNoteModal(true))
                  .catch((err) => {
                    console.log(err);
                  })
                }
              >
                Save
              </Button>
            </Row>
            <Form.Item
              name={"name"}
              label={<FieldLabel bold label={"Name"} />}
              rules={getRules("name", true)}
              validateTrigger="onSubmit"
            >
              <Input
                placeholder={`Enter case name`}
                maxLength={512}
                onChange={(e) => {
                  form.setFieldValue("name", capitalizeWords(e.target.value))
                }}
              />
            </Form.Item>
            <Form.Item
              name={"description"}
              label={<FieldLabel bold label={"Description"} />}
              validateTrigger="onSubmit"
            >
              <TextArea
                placeholder={`Enter case description`}
                maxLength={4096}
                rows={5}
              />
            </Form.Item>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="assigneeID"
                  label={<FieldLabel bold label={"Assignee"} />}
                  rules={getRules("assigneeID", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Object.entries(users).map(([id, u]) => ({
                      label: u.firstName + ' ' + u.lastName,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="userID"
                  label={<FieldLabel bold label={"Reporter"} />}
                >
                  <Select
                    disabled={true}
                    options={Object.entries(users).map(([id, u]) => ({
                      label: u.firstName + ' ' + u.lastName,
                      value: id,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="severity"
                  label={<FieldLabel bold label={"Severity"} />}
                  rules={getRules("severity", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CaseSeverityMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="priority"
                  label={<FieldLabel bold label={"Priority"} />}
                  rules={getRules("priority", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CasePriorityMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>   
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  name="status"
                  label={<FieldLabel bold label={"Status"} />}
                  rules={getRules("status", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CaseStatusMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="category"
                  label={<FieldLabel label={"Category"} />}
                  rules={getRules("category", true)}
                  validateTrigger="onSubmit"
                >
                  <Select
                    showAction={["focus", "click"]}
                    options={Array.from(CaseCategoriesMap, ([k, v]) => ({
                      label: v,
                      value: k,
                    }))}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item 
              name="tags"
              label={<FieldLabel bold label={"Labels"}/>}
              validateTrigger="onSubmit"
            >
              <Select
                showAction={["focus", "click"]}
                placeholder={`Enter labels`}
                allowClear
                mode="tags"
              />
            </Form.Item>
          </Space>
        </Spin>
      </Form>
      {addNoteModal
        &&
        <CaseMgmtNote 
          caseId={caseIn.id}
          open={addNoteModal}
          hideNotification={true}
          hideAttachment={true}
          onClose={() => setAddNoteModal(false)}
          onSubmit={() => updateCurrentCase()}
        />
      }
    </>
  );
};