import { Spin, Typography, theme, Space, Card, Timeline, Avatar } from "antd";
import {
  HistoryOutlined,
} from "@ant-design/icons";

import { FC, useEffect, useState } from "react";
import { useOrganizationStore, useUserInfoStore, useCaseActivityStore, useCaseNotesStore, useCaseAttachmentStore, useCaseStore } from "store";
import { HLink } from "components/Link";
import { dateTimeFormatOptions } from "types";

const { Text } = Typography;

export interface CaseMgmtActivitiesProps {
  caseId: string;
}

export const CaseMgmtActivities: FC<CaseMgmtActivitiesProps> = ({
  caseId
}) => {
  const { token } = theme.useToken();
  const [loader, setLoader] = useState(false);
 
  const context = useOrganizationStore((state) => state.context);

  const { userInfos, loadUserInfos } = useUserInfoStore((state) => ({
    userInfos: state.userInfos,
    loadUserInfos: state.loadUserInfos,
  }));

  const {
    caseActivities,
    getCaseActivities,
    clearCaseActivities
  } = useCaseActivityStore((state) => ({
    caseActivities: state.activities,
    getCaseActivities: state.getCaseActivities,
    clearCaseActivities: state.clearCaseActivities,
  }));
  
  const cases = useCaseStore((state) => state.cases);
  const caseNotes = useCaseNotesStore((state) => state.notes);
  const caseAttachments = useCaseAttachmentStore((state) => state.attachments);

  const loadCaseActivities = async (caseId: string) => {
    try {
      setLoader(true);
      clearCaseActivities();
      await getCaseActivities(caseId, true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    caseId && caseId != "" && loadCaseActivities(caseId);
  }, [caseId, cases, caseNotes, caseAttachments, context]);
  
  //Fetch user info for all users linked to case activities
  useEffect(() => {
    try {
      const userIds = [] as string[];
      caseActivities.map((a) => {
        a.userID && a.userID != "" && userIds.push(a.userID);
      });
      userIds.length > 0 && loadUserInfos(userIds);
    } catch (error) {
      console.log(error);
    }
  }, [caseActivities]);

  return (
    <>
      <Spin spinning={loader}>
        <Card
          size="small"
          title={
            <Space direction="horizontal">
              <HistoryOutlined style={{ color: token.colorTextLightSolid }}/>
              <Text style={{ color: token.colorTextLightSolid }}>
                History
              </Text>
            </Space>
          }
          styles={{
            header: {
              backgroundColor: token.colorPrimary,
              textAlign: "left",
            },
            body: {
              backgroundColor: token.colorBgContainer,
              padding: token.padding,
              height: "auto",
              maxHeight: "100%",
              overflowX: "hidden",
            }
          }}
        >
          <Timeline>
            {caseActivities?.length &&
              caseActivities.map((activity, index) => (
                <Timeline.Item 
                  key={activity.id} 
                  dot={userInfos[activity.userID]?.logoUrl && (
                    <Avatar src={userInfos[activity.userID]?.logoUrl} size={"small"}/>
                  )}
                >
                  <Space direction="vertical">
                    <HLink href={"mailto:" + userInfos[activity.userID]?.email} text={`${userInfos[activity.userID]?.firstName} ${userInfos[activity.userID]?.lastName}`} />
                    {activity.action}
                    {new Date(activity.createdAt).toLocaleTimeString(undefined,dateTimeFormatOptions)}
                  </Space>
                </Timeline.Item>
              ))
            }
          </Timeline>
        </Card>
      </Spin>
    </>
  );
};