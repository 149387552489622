import { axiosInterceptor } from "./axios";
import {
  artifactProvidersApiPath,
  artifactsApiPath,
  jsonApiDeserializer, popularSearchesApiPath, recentSearchesApiPath, searchesApiPath,
  searchPoliciesApiPath,
  searchRunActionsApiPath, searchRunsApiPath, searchxRunApiPath
} from "./constant";
import { dtoFactory } from "utility/resource";
import { isProduction } from "api/constant";

import { AxiosResponse } from "axios";
import {
  Artifact,
  RecentPopularSearch,
  Search,
  SearchRun,
  SearchRunAction,
  SearchRunRecommendedActions,
  SpecActionType,
  SpecStateType,
  SearchRunActionType,
  Policy,
  ArtifactProvider,
} from "types";

export const runSearchXApi = async (query: string, isAiEnabledForSearch: boolean): Promise<SearchRun> => {
  const response = await axiosInterceptor(
    "post",
    searchxRunApiPath,
    {
      data: {
        type: "search-x",
        attributes: {
          query: query,
          isAiBasedQuery: isAiEnabledForSearch,
          isTagBasedSearch: isProduction ? false : true,
        },
      },
    }
  );
  return jsonApiDeserializer.deserialize(response.data)  as SearchRun;
};

export const runSearchApi = async (id: string): Promise<SearchRun> => {
  const response = await axiosInterceptor(
    "post",
    `${searchesApiPath}/${id}/run`,
    {
        "data": {
          "type": "searches",      
          "id": id,
          "attributes": {
            isTagBasedSearch: isProduction ? false : true,
          },
        }
    }
  );
  return jsonApiDeserializer.deserialize(response.data)  as SearchRun;
};

export const getSearchRunApi = async (searchRunId?: string): Promise<SearchRun> => {
  const response = await axiosInterceptor(
    "get",
    `${searchRunsApiPath}/${searchRunId}`
  );

  return jsonApiDeserializer.deserialize(response.data)  as SearchRun;
};

export const deleteSearchRunApi = async (searchRunId?: string) : Promise<void>=> {
  await axiosInterceptor(
    "delete",
    `${searchRunsApiPath}/${searchRunId}`
  );

  return;
};


export const actionSearchRunApi = async (
  searchRunId: string,
  actionType: SearchRunActionType,
  actionData?: unknown,
): Promise<any> => {
  const response = await axiosInterceptor(
    "post",
    `${searchRunsApiPath}/${searchRunId}/${actionType}`,
      actionData 
      && {
            "data": {
              "type": "search-runs",      
              "id": searchRunId,
              "attributes": actionData,
            }
          }
    );
  return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};

export const getSearchRunRecommendedActionsApi = async (searchId?: string) : Promise<SearchRunRecommendedActions>=> {
  const response = await axiosInterceptor(
    "get",
    `${searchRunsApiPath}/${searchId}/recommended-actions`
  );
  return jsonApiDeserializer.deserialize(response.data)  as SearchRunRecommendedActions;
};

export const getRecentSearchesApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[]
): Promise<[Search[], number]> => {
  let url = `${recentSearchesApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}`;
  
  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get", url);
  
  const recentSearches = jsonApiDeserializer.deserialize(response.data)  as RecentPopularSearch[];
  
  const searches: Search[] = [];
  recentSearches.forEach( (recentSearch) => {
    searches.push({
      id: recentSearch.id,
      name: recentSearch.searchMetaData.name,
      description: recentSearch.searchMetaData.description,
      query: recentSearch.searchMetaData.query,
      nativeQuery: recentSearch.searchMetaData.nativeQuery,
      isAiBasedQuery: recentSearch.searchMetaData.isAiBasedQuery,
      hits: recentSearch.hits,
    } as Search)
  });

  return [searches, response?.data?.meta?.totalCount];
};


export const getPopularSearchesApi = async ( 
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[]
): Promise<[Search[], number]> => {
    
  let url = `${popularSearchesApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}`;

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get", url);
  
  const popularSearches = jsonApiDeserializer.deserialize(response.data)  as RecentPopularSearch[];
  
  const searches: Search[] = [];
  popularSearches.forEach( (popularSearch) => {
    searches.push({
      id: popularSearch.id,
      name: popularSearch.searchMetaData.name,
      description: popularSearch.searchMetaData.description,
      query: popularSearch.searchMetaData.query,
      nativeQuery: popularSearch.searchMetaData.nativeQuery,
      isAiBasedQuery: popularSearch.searchMetaData.isAiBasedQuery,
      hits: popularSearch.hits,
    } as Search)
  });
  
  return [searches, response?.data?.meta?.totalCount];
};

export const getSavedSearchesApi = async (
  pageNumber: number,
  pageSize: number,
  filters?: string[],
  sorter?: string[]
) : Promise<[Search[], number]> => {

  let url = `${searchesApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}`;

  if (filters && filters.length > 0) {
    filters.map((filter) => {
      url += `&filter=${filter}`
    });
  }

  if (sorter && sorter.length > 0) {
    sorter.map((sort) => {
      url += `&sort=${sort}`
    });
  }

  const response = await axiosInterceptor("get", url);

  const searches = jsonApiDeserializer.deserialize(response.data)  as Search[];
  return [searches, response?.data?.meta?.totalCount];
};

export const createSearchApi = async (
  search: Search, 
  tagsOnly?: boolean,
) : Promise <Search> => {

  let url = `${searchesApiPath}`;
  if (tagsOnly) {
    url += "?tagsOnly=true"
  }

  const response = await axiosInterceptor(
    "post",
    url,
    {
      data: {
        type: "searches",
        attributes: search,
      },
    }
  );

  const searchOut = jsonApiDeserializer.deserialize(response.data)  as Search;
  return searchOut;
};

export const updateSearchApi = async (
  searchId: string,
  name: string,
  description: string
): Promise<AxiosResponse<any, any>>=> {
  const response = await axiosInterceptor(
    "patch",
    `${searchesApiPath}/${searchId}`,
    {
      data: {
        id: searchId,
        type: "searches",
        attributes: {
          name: name,
          description: description,
        },
      },
    }
  );
  return response;
};

export const deleteSearchApi = async (searchId: string): Promise<AxiosResponse<any, any>> => {
  const response = await axiosInterceptor(
    "delete",
    `${searchesApiPath}/${searchId}`
  );

  return response;
};


export const getSearchRunActionApi = async (searchRunActionId?: string): Promise<SearchRunAction> => {
  const response = await axiosInterceptor(
    "get",
    `${searchRunActionsApiPath}/${searchRunActionId}`
  );

  return jsonApiDeserializer.deserialize(response.data)  as SearchRunAction;
};

export const actionSearchRunActionApi = async (
  searchRunActionId: string,
  actionType: SearchRunActionType,
): Promise<any> => {
  const response = await axiosInterceptor(
    "post",
    `${searchRunActionsApiPath}/${searchRunActionId}/${actionType}`
    );
  return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const getSearchRunActionsBySearchRunIdsApi = async (searchRunIds: string,  pageNumber: number, pageSize: number): Promise<SearchRunAction[]> => {
  const searchRunIdArr = searchRunIds.split(",");
  const modifiedSearchRunIdArr = searchRunIdArr.map((searchRunId: string) => `'${searchRunId}'`);
  const modifiedSearchRunIds = modifiedSearchRunIdArr.join(",");
  const url = `${searchRunActionsApiPath}?page[number]=${pageNumber}&page[size]=${pageSize}&filter=equals(searchRunID,${modifiedSearchRunIds})`
  const response = await axiosInterceptor("get",url);
  return jsonApiDeserializer.deserialize(response.data) as SearchRunAction[];
};

export const deleteSearchRunActionApi = async (searchRunActionId?: string) : Promise<void>=> {
  await axiosInterceptor(
    "delete",
    `${searchRunActionsApiPath}/${searchRunActionId}`
  );

  return;
};


export const getArtifactsApi = async (
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[Artifact[], number]> => {  
  let url = `${artifactsApiPath}?accessInfoRequired=true`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get", url);
  const artifacts =  jsonApiDeserializer.deserialize(response.data)  as Artifact[];
  return [artifacts, response?.data?.meta?.totalCount];
};

export const getArtifactVersionsApi = async (artifactId: string):Promise<Artifact[]> => {
  if(artifactId) {
    const response = await axiosInterceptor(
      "get",
      `${artifactsApiPath}}?includeVersions=true&filter=equals(id,'${artifactId}')&fields[artifacts]=state,comments`
    );
    
    return jsonApiDeserializer.deserialize(response.data)  as Artifact[];
  }
  throw new Error("artifactId should be present in the artifact versions get");
};


export const getArtifactApi = async (aritfactId: string) :Promise<Artifact>=> {
  const response = await axiosInterceptor("get", (`${artifactsApiPath}/${aritfactId}?accessInfoRequired=true`));
  return jsonApiDeserializer.deserialize(response.data) as Artifact;
};

export const createArtifactApi = async (artifact: Artifact) :  Promise<Artifact>=> {
  const response = await axiosInterceptor(
    "post",
    artifactsApiPath,
    {
        "data": {
            "type": "artifacts",
            "attributes": dtoFactory.convertArtifactToUploadObject(artifact),
        }
    }
  );
  const artifactOut = jsonApiDeserializer.deserialize(response.data)  as Artifact;
  return artifactOut;
};

export const updateArtifactApi = async (artifact: Artifact): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${artifactsApiPath}/${artifact.id}`,
    {
      "data": {
        "type": "artifacts",      
        "id": artifact.id,
        "attributes": dtoFactory.convertArtifactToUploadObject(artifact),
      }
    }
  );
};

export const actionArtifactApi = async (artifactId: string, action: SpecActionType, artifact?: unknown) => {
  const response = await axiosInterceptor(
    "post",
    `${artifactsApiPath}/${artifactId}/${action}`,
    artifact
      && {
            "data": {
              "type": "artifacts",      
              "id": artifactId,
              "attributes": artifact,
            }
          }
    );
    return response && response.data ? jsonApiDeserializer.deserialize(response.data) : null;
};


export const deleteArtifactApi = async (artifactId: string): Promise<AxiosResponse<any,any>>=> {
  return  await axiosInterceptor("delete",`${artifactsApiPath}/${artifactId}`);
};

export const getSearchPoliciesApi = async (category:string, name?: string): Promise<Policy[]> => {
  let url = `${searchPoliciesApiPath}?filter=equals(category,'${category}')`;
  
  if (name) 
    url += `&filter=equals(name,'${name}')`

  const response = await axiosInterceptor("get", url);
  return jsonApiDeserializer.deserialize(response.data) as Policy[];
};

export const updateSearchPolicyApi = async (policy: Policy): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "patch",
    `${searchPoliciesApiPath}/${policy.id}`,
    {
      "data": {
        "type": "policies",      
        "id": policy.id,
        "attributes": policy,
      }
    }
  );
};


export const createSearchArtifactProviderPolicyApi = async (policy: Policy): Promise<AxiosResponse<any, any>> => {
  return axiosInterceptor(
    "post",
    searchPoliciesApiPath,
    {
      "data": {
        "type": "policies",      
        "id": policy.id,
        "attributes": policy,
      }
    }
  );
};

export const deleteSearchArtifactProviderPolicyApi = async (policyId: string): Promise<void> => {
  await axiosInterceptor(
    "delete",
    `${searchPoliciesApiPath}/${policyId}`
  );
};


export const getSearchArtifactProviderPoliciesApi = async (
  pageNumber?: number,
  pageSize?: number,
) :  Promise<[ArtifactProvider[], number]> => {  
  let url = `${artifactProvidersApiPath}?&subscribedAppsOnly=true&includePolicyArtifacts=true&include=artifacts&fields[artifact-providers]=appID,artifactID,appInfo&fields[artifacts]=name,displayName,description`
  if (pageNumber) 
    url += `&page[number]=${pageNumber}`
  if (pageSize) 
    url += `&page[size]=${pageSize}`
  
  const response = await axiosInterceptor("get", url)
  const actionProviders =  jsonApiDeserializer.deserialize(response.data)  as ArtifactProvider[];
  return [actionProviders, response?.data?.meta?.totalCount];
};
